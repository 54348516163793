import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Include } from "../models/classes/include";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IncludeService {
  constructor(private _http: HttpClient) {}

  //Fetch all includes
  GetAll() {
    const url = environment.API_URL + "/api/include/all";
    return this._http.get<Include[]>(url);
  }

  GetAllNew() {
    const url = environment.API_URL + "/api/hotelinclude/all";
    return this._http.get<Include[]>(url);
  }
}
