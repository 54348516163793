declare var $: any;
export class Loader {
    static Show() {
        // tslint:disable-next-line:quotemark
        $("#loader").removeClass("displayNone").addClass("displayFlex");
    }
    static Hide() {
        // tslint:disable-next-line:quotemark
        $("#loader").removeClass("displayFlex").addClass("displayNone");
    }
}
