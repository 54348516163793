import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Server } from "../server";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AgencyProfileService {
  constructor(private _http: HttpClient, private _server: Server) {}
  //baseUrl = 'api/agencyprofile';

  Getall() {
    // debugger
    const url = environment.API_URL + "/api/agencyprofile/agencies";
    return this._http.get(url);
  }

  GetByUser(id) {
    const url = environment.API_URL + "/api/agencyprofile/getbyuser/" + id;
    return this._http.get(url);
  }

  //Save data
  Save(model: any) {
    const url = environment.API_URL + "/api/agencyprofile/register";
    return this._http.post(url, model);
  }
}
