import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { SessionManagement } from "../session-management";
import { ServiceService } from "./service.service";
import { ActivityService } from "./activity.service";
import { CountryService } from "./country.service";
import { CityService } from "./city.service";
import { HotelService } from "./hotel.service";
import { IncludeService } from "./include.service";
import { PackageService } from "./package.service";
import { HttpClient } from "@angular/common/http";
import { CheckoutService } from "./checkout.service";
import { UserbookingService } from "./userbooking.service";
import { FavouriteService } from "./favourite.service";
import { AgencyService } from "./agency.service";
import { TransactionService } from "./transaction.service";
import { SiteVisitService } from "./site-visit.service";
import { ContactService } from "./contact.service";
import { TripProductService } from "./trip-product.service";
import { AgencyProfileService } from "./agencyprofile.service";
import { LocalService } from "./local.service";
import { PackagecategorymasterService } from "./packagecategorymaster.service";
import { CustomTripService } from "./CustomTripService";
import { RoleServices } from "./role.service";
import { TripPlanner } from "./trip-planner.service";
import { environment } from "src/environments/environment";
// import {  } from "";

@Injectable({
  providedIn: "root",
})
export class Service {
  // tslint:disable-next-line:no-shadowed-variable
  constructor(
    // tslint:disable-next-line:no-shadowed-variable
    public UserService: UserService,
    public ServiceService: ServiceService,
    public ActivityService: ActivityService,
    public CountryService: CountryService,
    public CityService: CityService,
    public HotelService: HotelService,
    public IncludeService: IncludeService,
    public Packageservice: PackageService,
    public _http: HttpClient,
    public CheckoutService: CheckoutService,
    public UserBookingService: UserbookingService,
    public FavouriteService: FavouriteService,
    public AgencyService: AgencyService,
    public TransactionSerive: TransactionService,
    public SiteVisitService: SiteVisitService,
    public ContactService: ContactService,
    public TripProductService: TripProductService,
    public AgencyProfile: AgencyProfileService,
    public LocalService: LocalService,
    public PackageCategoryMaster: PackagecategorymasterService,
    public CustomTripService: CustomTripService,
    public RoleServices: RoleServices,
    public TripPlanner: TripPlanner,
    private sessionManagement: SessionManagement
  ) {}

  GetTheUserId() {
    const userId = this.sessionManagement.GetUser().Id;
    return userId;
  }

  AddImage(model: any) {
    // debugger;
    // const url = environment.API_URL +'/api/upload'
    const url = environment.API_URL + "/api/uploadimage/upload/";
    return this._http.post(url, model, {
      headers: { "Content-Type": "application/json" },
    });
  }
}
