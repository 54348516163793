<div class="prtm-wrapper">
  <header class="prtm-header frunt">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" [routerLink]='["/"]'>
            <img src="assets/img/logo-header.png" alt="logo" />
          </a>
        </div>
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-left">
            <li id="li-special-offers" (click)="HeaderClass('li-special-offers')"><a [routerLink]='["/offers"]'>{{
                'ElPackages' | translate }}</a></li>
            <li id="li-planedtrip" (click)="HeaderClass('li-ac')"><a [routerLink]='["/activitypannel"]'>{{'Activity
                Panel' | translate}}</a></li>
            <li id="li-custom-trip"><a [routerLink]='["/customtrip"]'>{{ 'Request custom trip' | translate }}</a></li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <!-- <li class="dropdown" id="li-mytrip" (click)="HeaderClass('li-mytrip')">
              <a *ngIf="!UserLoggedIn" [routerLink]='["/login"]'>
                <i class="fas fa-briefcase"></i>&nbsp;&nbsp;{{ 'My Trips' | translate }}</a>

              <a *ngIf="UserLoggedIn" [routerLink]='["/user/mytrip"]'><i class="fas fa-briefcase"></i>
                &nbsp;&nbsp;{{ 'My Trips' | translate }}</a>
            </li> -->

            <li class="dropdown" *ngIf="!UserLoggedIn" id="li-login" (click)="HeaderClass('li-login')">
              <a [routerLink]='["/login"]' class="dropdown-toggle" data-toggle="dropdown" role="button"
                aria-haspopup="true" aria-expanded="false">{{ 'Signup/Login' | translate }}</a>
            </li>

            <li class="dropdown" *ngIf="UserLoggedIn">
              <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">
                <img *ngIf="imagePath" class="img-responsive display-ib mrgn-l-sm img-circle" [src]="imagePath"
                  width="64" height="64">
                <img *ngIf="!imagePath" class="img-responsive display-ib mrgn-l-sm img-circle"
                  src="assets/img/default-user.png" width="64" height="64" alt="User-image">
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li><a [routerLink]="['/user/profile']"><i class="fa fa-user"></i>
                    {{ 'My Profile' | translate }}</a></li>

                <li *ngIf="userRole == 'Admin'"><a [routerLink]="['/admin']"><i class="fas fa-users-cog"></i>
                    {{ 'adminPanal' | translate }}</a></li>

                <li *ngIf="userRole == 'Expert'"><a [routerLink]="['/expert']"><i class="fas fa-users-cog"></i>
                    {{ 'adminPanal' | translate }}</a></li>


                <li><a (click)="Logout()"><i class="fa fa-power-off"></i>{{ 'Logout' | translate }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div><!-- /.navbar-collapse -->
      </div><!-- /.container-fluid -->
    </nav>
  </header>
  <router-outlet></router-outlet>
</div>
<!-- End header -->
<!-- === Start Footer  === -->
<footer class="footer-front-page">
  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
      <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
        <a [routerLink]='["/"]'><img class="footer-logo-front" src="assets/img/logo-header.png" alt="footer logo"></a>
      </div>

      <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
        <h2>{{ 'MENU' | translate }}</h2>
        <ul class="front-footer">
          <li><a [routerLink]='["/"]' class="home-footer-menu">{{ 'Home' | translate }}</a></li>
          <li><a [routerLink]='["/offers"]' class="offer-footer-menu">رحلات</a></li>
          <li><a [routerLink]='["/activitypannel"]' class="activity-footer-menu">نشاطات</a></li>
          <li><a [routerLink]='["/user/profile"]' class="profile-footer-menu">{{ 'My acount' | translate }}</a></li>
          <!-- <li><a [routerLink]='["/offers"]'>{{ 'Special offers' | translate }}</a></li> -->
          <li><a [routerLink]='["/customtrip"]'
              class="custom-trip-footer-menu">{{ 'Request custom trip' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
        <h2>{{ 'INFO' | translate }}</h2>
        <ul class="front-footer">
          <li><a [routerLink]='["/privacy-policy"]'
              class="privacy-policy-footer-menu">{{ 'Privacy Policy' | translate }}</a></li>
          <!-- <li><a [routerLink]='["/qualityandassurance"]'>{{ 'Common questions' | translate }}</a></li> -->
          <li><a [routerLink]='["/termandcondition"]'
              class="terms-and-conditions-footer-menu">{{ 'Terms and Conditions' | translate }}</a></li>

          <li><a [routerLink]='["/about"]' class="about-us-footer-menu">{{ 'About Company' | translate }}</a></li>
          <!-- <li><a href="javascript:void(0)">Code</a></li> -->
          <!-- <li><a href="javascript:void(0)">{{ 'Customers service' | translate }}</a></li> -->
        </ul>
      </div>
      <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
        <h2>{{ 'CONTACT US' | translate }}</h2>
        <ul class="front-footer">
          <li><i class="fa fa-envelope-o" class="mail-us-footer-menu"></i>&nbsp;&nbsp;hello@trippat.com</li>
          <!-- <li><i class="fa fa-phone"></i>&nbsp;&nbsp;9200332090</li> -->
          <!-- <li><a href="javascript:void(0)"></a></li> -->
          <!-- <li><a href="javascript:void(0)"></a></li> -->
        </ul>
      </div>
      <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2">
        <h2>{{ 'FIND US' | translate }}</h2>
        <ul class="front-footer">
          <li><a href="https://www.instagram.com/trippatapp/" class="instagram-footer-menu"><i
                class="fa fa-instagram"></i>&nbsp;&nbsp;instagram</a>
          </li>
          <li><a href="https://twitter.com/trippatapp/" class="twitter-footer-menu"><i
                class="fa fa-twitter"></i>&nbsp;&nbsp; twitter</a></li>
          <!-- <li><a href="javascript:void(0)"><i class="fa fa-facebook"></i>&nbsp;&nbsp;facebook</a></li> -->
        </ul>
      </div>

      <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
    </div>


    <div class="row copyright-front-footer">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xs-12">
        <p>Copyright 2021 Trippat. All Rights Reserved</p>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xs-12 visa-footer">
        <img src="assets/img/visa-footer.png" alt="footer logo">
      </div>
    </div>
  </div>
  <!-- <a href="javascript:void(0);" id="back-top" class="to-top scrolled"> <span class="to-top-icon"></span> </a> -->
</footer>