import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TripPlanner {
  constructor(private _http: HttpClient) {}

  baseUrl = environment.API_URL + "/api/tripplanner/";

  save(model: any) {
    // debugger
    let url = this.baseUrl + "save";
    return this._http.post(url, model);
  }

  GetAllByPaging(page: number, Page_size: number, search: string) {
    // debugger
    var url =
      this.baseUrl + "/paging/all?page=" + page + "&page_size=" + Page_size;
    if (search) {
      url =
        this.baseUrl +
        "/paging/all?page=" +
        page +
        "&page_size=" +
        Page_size +
        "&search=" +
        search;
    }

    return this._http.get(url);
  }

  GetById(id: number) {
    return this._http.get(this.baseUrl + id);
  }

  SaveActivity(model: any) {
    // debugger
    let url = this.baseUrl + "create/activity";
    return this._http.post(url, model);
  }

  SaveHotel(model: any) {
    // debugger
    let url = this.baseUrl + "create/hotel";
    return this._http.post(url, model);
  }

  UpdateAccomdation(Id, HotelId) {
    // debugger
    var url = this.baseUrl + "update/Hotel?Id=" + Id + "&HotelId=" + HotelId;
    return this._http.get(url);
  }

  UpdateActivity(Id, ActivityId) {
    // debugger
    var url =
      this.baseUrl + "update/Activity?Id=" + Id + "&ActivityId=" + ActivityId;
    return this._http.get(url);
  }
  DeleteActivity(Id) {
    // debugger
    var url = this.baseUrl + "delete/Activity?Id=" + Id;
    return this._http.get(url);
  }

  DeleteHotel(Id) {
    // debugger
    var url = this.baseUrl + "delete/Hotel?Id=" + Id;
    return this._http.get(url);
  }
}
