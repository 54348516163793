import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { Service } from "../services/service";
import { User } from "../models/user";
import { FormBuilder } from "@angular/forms";
import { Data } from "../models/data";
import { AppConfig } from "../app.config";
import { Router } from "@angular/router";
import { Loader } from "../common";
import {
  SocialAuthService,
  SocialUser,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { SessionManagement } from "../session-management";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { SiteVisitor } from "../models/classes/siteVisitor";
import { Pageview } from "../models/classes/PageView";
import { StorageService } from "../shared/services/storage.service";

declare var $: any;
@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.css"],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  requestCount = 0;
  UserLoggedIn = false;
  IsAdmin = false;
  Name;
  imagePath;
  Language;
  userRole;

  constructor(
    private _service: Service,
    private _router: Router,
    // private authService: SocialAuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastr: ToastrService,
    private translate: TranslateService,
    private storageService: StorageService,
    private sessionManagement: SessionManagement
  ) {
    this.storageService.set("Language", "ar");

    if (this.storageService.get("Language")) {
      if (this.storageService.get("Language") == "ar") {
        this.Language = "Arabic";
      } else {
        this.Language = "English";
      }
    } else {
      translate.setDefaultLang("ar");
      this.storageService.set("Language", "ar");
      this.Language = "Arabic";
    }
  }

  _currency: string;
  _language: string;

  ngOnInit() {
    this.GetUser();
    this.GetSiteVisitor();
    this.UserLoggedIn = this.sessionManagement.GetUser() != null;
    this.IsAdmin =
      this.sessionManagement.GetUser() != null
        ? this.sessionManagement.GetUser().RoleId == AppConfig.Role.Admin
          ? true
          : false
        : false;
    if (isPlatformBrowser(this.platformId)) {
      $(".navbar-nav li").removeClass("active");
      $("#" + this.storageService.get("liActiveClass")).addClass("active");
    }
    this.storageService.remove("liActiveClass");

    if (this.storageService.get("Language") == "ar") {
      this.Language = "Arabic";
      if (isPlatformBrowser(this.platformId)) {
        $("body").addClass("arabic");
      }
    } else {
      this.Language = "English";
      //$("[name='bootstrap-rtl']").remove();
      if (isPlatformBrowser(this.platformId)) {
        $("body").addClass("arabic");
      }
    }
  }
  ngAfterViewInit() {}

  RedirectUser() {
    this.ngOnInit();
  }

  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
  //     this.SocialLogin(data);
  //   });
  // }

  ShowForgotPassword() {
    if (isPlatformBrowser(this.platformId)) {
      $(".forgot-password-form").addClass("show");
    }
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data) => {
  //     this.SocialLogin(data);
  //   });
  // }
  SocialLogin(data: any) {
    this.UpCouter();
    this._service.UserService.SocialLogin(data).then((data: Data<User>) => {
      this.DownCounter();
      if (data.returnCode === 0) {
        this.sessionManagement.SetUser(data.data);
        this.RedirectUser();
      } else {
        this.ErrorToaster(data.returnMessage);
      }
    });
  }

  UpCouter() {
    this.requestCount++;
    if (this.requestCount === 0) {
      Loader.Hide();
    } else {
      Loader.Show();
    }
  }

  DownCounter() {
    this.requestCount--;
    if (this.requestCount === 0) {
      Loader.Hide();
    } else {
      Loader.Show();
    }
  }

  ErrorToaster(message) {
    this.toastr.error(message, null, {
      timeOut: 5000,
      closeButton: false,
      positionClass: "toast-top-center",
    });
  }
  SuccessToaster(message) {
    this.toastr.clear();
    this.toastr.success(message, null, {
      timeOut: 5000,
      closeButton: false,
      positionClass: "toast-top-center",
    });
  }

  HeaderClass(value: any) {
    // debugger
    if (isPlatformBrowser(this.platformId)) {
      $(".navbar-nav li").removeClass("active");
      $("#" + value).addClass("active");
    }
    this.storageService.set("liActiveClass", value);
  }

  AddLanguage(lang: string) {
    this._language = lang;
  }

  AddCurrency(currency: string) {}

  ChangeLang(lang: string) {
    this.translate.use(lang);
  }

  GetUser() {
    var value = this.sessionManagement.GetUser();
    if (value) {
      if (value.UserProfile) {
        this.imagePath =
          this.sessionManagement.GetUser().UserProfile.ProfilePicPath;
        this.Name = this.sessionManagement.GetUser().UserProfile.FirstName;
        this.userRole = this.sessionManagement.GetUser().Role.Name;
      }
    }
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  Logout() {
    this.storageService.clear();
    this.storageService.set("Language", "ar");
    this._router.navigateByUrl("/");
    this.ngOnInit();
  }

  GetSiteVisitor() {
    var model = new SiteVisitor();
    var pageview = new Pageview();
    model.CreatedOn = new Date();
    model.TotalVisit = 1;
    pageview.URL = this._router.url;
    pageview.CreatedOn = new Date();
    pageview.TotalVisit = 1;
    model.PageView = pageview;
    model.UserId =
      this.sessionManagement.GetUser() != null
        ? this.sessionManagement.GetUser().Id
        : null;
    // this._service.SiteVisitService.Create(model).then((data) => {});
  }

  Translate(lang: string) {
    // debugger
    this.translate.use(lang);
    if (lang == "ar") {
      this.Language = "Arabic";
      if (isPlatformBrowser(this.platformId)) {
        $("body").addClass("arabic");
      }
    } else {
      this.Language = "English";
      //$("[name='bootstrap-rtl']").remove();
      if (isPlatformBrowser(this.platformId)) {
        $("body").removeClass("arabic");
      }
    }
    this.storageService.set("Language", lang);
    window.location.reload();
  }
}
