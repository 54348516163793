import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class MetaTagsService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
  ) { }

  setTitle(title) {
    this.translate.get(title ? title : "Titles.Home").subscribe((result) => {
      this.titleService.setTitle(
        `${this.translate.instant("Trippat")} | ${this.translate.instant(
          result
        )}`
      );
    });
  }

  setDescription(description) {
    this.translate
      .get(description ? description : "Descriptions.Home")
      .subscribe((result) => {
        this.metaService.updateTag({
          name: "description",
          content: this.translate.instant(result),
        });
      });
  }
  setMetaByName(name, content) {
    // this.translate
    //   .get(content)
    //   .subscribe(result => {
    //     this.metaService.addTag({
    //       name,
    //       content,
    //     })
    //   });
    this.metaService.addTag({
      name,
      content,
    })
  }
  setMetaByProperty(property, content) {
    // this.translate
    //   .get(content)
    //   .subscribe(result => {
    //     this.metaService.addTag({
    //       property,
    //       content,
    //     });
    //   });
    this.metaService.addTag({
      property,
      content,
    });
  }
}
