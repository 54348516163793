import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AdminDashpordService {
  constructor(private http: HttpClient) {}

  getDashpordData() {
    const url = environment.API_URL + `/api/statistics`;
    return this.http.get(url);
  }

  getDashboardDataForExpert() {
    return this.http.get(`${environment.API_URL}/api/statistics/expert`);
  }
}
