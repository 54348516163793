import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Server } from "../server";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AgencyService {
  constructor(private _http: HttpClient, private _server: Server) {}
  baseUrl = environment.API_URL + "/api/agency";

  GetAll(page: number, page_size: number) {
    // debugger;
    const url = this.baseUrl + "/all?page=" + page + "&page_size=" + page_size;
    return this._http.get(url);
  }

  GetByUser(id) {
    const url = this.baseUrl + "/getbyuser/" + id;
    return this._http.get(url);
  }

  Create() {}

  Update(model: any) {
    const url = this.baseUrl + "/update";
    return this._server.Post(url, model);
  }

  Delete(model: any) {
    const url = this.baseUrl + "/update";
    return this._server.Post(url, model);
  }

  UpdateAgencyProfile(model: any) {
    const url = "/api/agencyprofile/update";
    return this._server.Post(url, model);
  }

  UpdateUserAgencyProfile(model: any) {
    const url = "/api/agencyprofile/update/agency";
    return this._server.Post(url, model);
  }

  UploadLogo(model: any) {
    // debugger
    // model.Id=37;
    //const url = "http://trippat.com/api/agencyprofile/logo";
    const url = "/api/agencyprofile/logo";
    return this._server.Post(url, model);
  }
}
