import { Package } from "../package";

export class Favourite {
  Id: number;
  UserId: number;
  PackageId: number;
  ActivityId: number;
  IsActive: boolean;
  CreatedOn: Date;
  UpdatedOn: Date;
  Package: Package[];
}
