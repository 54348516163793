import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckoutService } from 'src/app/services/checkout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  newCheckoutPaymentId: string;
  shopperResultUrl: string;
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {PaymentCheckoutId: string},
    public dialogRef: MatDialogRef<PaymentComponent>,
    private checkoutService: CheckoutService
  ) {

  }

  ngOnInit(): void {
    this.loading = true;
    if(this.data.PaymentCheckoutId){
      this.reGenerate(this.data.PaymentCheckoutId)
      this.shopperResultUrl = `${location.href}`;
    }else {
      this.close()
    }
  }

  reGenerate(paymentCheckoutId){
    this.checkoutService.reGeneratePaymentCheckoutId(paymentCheckoutId).subscribe((done:any) => {
      if(done.returnCode === 0){
        this.newCheckoutPaymentId = done.data.PaymentCheckoutId;
        this.loadScripts(this.newCheckoutPaymentId);
      }
    })
  }

  loadScripts(checkoutId) {
    const script = `${environment.paymentGateScriptUrl}${checkoutId}`;
    const paymentScript = document.createElement("script");
    const langScript = document.createElement("script");
    paymentScript.src = script;
    paymentScript.type = "text/javascript";
    paymentScript.async = false;
    langScript.innerHTML = `var wpwlOptions = { locale: "ar" }`;
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(paymentScript);
    head.appendChild(langScript);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  close(state?){
    this.dialogRef.close(state)
  }

}
