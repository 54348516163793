import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Server } from "../server";
import { Activity } from "../models/Activity";
import { Service } from "./service";
import { PostWithUserAuth } from "../models/rest/post-user-auth";
import { UpdateUserAuth } from "../models/rest/update-user-auth";
import { FormGroup, FormControl } from "@angular/forms";
import { Country } from "../models/classes/country";
import { Data } from "../models/data";
import { Observable, observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CountryService {
  constructor(private _http: HttpClient, private _server: Server) {}
  baseUrl = environment.API_URL + "/api/country";

  GetAll() {
    const url = this.baseUrl + "/";
    return this._http.get(this.baseUrl);
  }

  GetAllByPaging(page: number, Page_size: number, search: string) {
    // debugger
    var url =
      this.baseUrl + "/paging/all?page=" + page + "&page_size=" + Page_size;
    if (search) {
      url =
        this.baseUrl +
        "/paging/all?page=" +
        page +
        "&page_size=" +
        Page_size +
        "&search=" +
        search;
    }

    return this._http.get(url);
  }

  async GetAll1() {
    return [];
  }
  Add(model: Country) {
    model.IsActive = true;
    return this._server.Post(
      environment.API_URL + "/api/country/create",
      model
    );
  }
  Update(id: number, model: Country) {
    return this._server.Post(
      environment.API_URL + "/api/country/update/" + id,
      model
    );
  }
  GetCountry(countryId: number) {
    return this._server.Get(environment.API_URL + "/api/country/" + countryId);
  }

  searchInCountriesCities(keyWord, page, pageSize) {
    // https://dev.trippat.com/api/country/countries-cities/search?page=1&page_size=10
    return this._http.get(
      `${environment.API_URL}/api/country/countries-cities/search?page=${page}&page_size=${pageSize}&search=${keyWord}`
    );
  }

  Delete(countryId: number) {
    return this._server.Post(
      environment.API_URL + "/api/country/delete/" + countryId,
      null
    );
  }

  GetCountriesById(countries: any) {
    // debugger
    const url = this.baseUrl + "/countries?countries=" + countries;
    return this._http.get(url);
  }

  GetCountriesByPackage() {
    const url = this.baseUrl + "/bypackage/all";
    return this._http.get(url);
  }

  GetByName(name: string) {
    return this._http.get(this.baseUrl + "/name/all/" + name);
  }

  IsCountryExist(name: string, id: number) {
    // tslint:disable-next-line:prefer-const
    let promise = new Promise<{ [key: string]: any } | null>(
      (resolve, reject) => {
        this._server
          .Post(environment.API_URL + "/api/country/countryexist", {
            Name: name,
            Id: id,
          })
          .then((data: Data<string>) => {
            if (data.returnCode == 0) {
              resolve(null);
            } else {
              resolve({ exist: true });
            }
          });
      }
    );
    return promise;
  }
}
