<div class="prtm-content">
  <div class="prtm-page-bar">
    <ul class="breadcrumb">
      <li class="breadcrumb-item text-capitalize">
      </li>
    </ul>
  </div>
  <div class="table-style">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="prtm-full-block">
          <div class="prtm-block-content">
            <div class="pad-all-lg">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xs-12">
                  <div class="main-box users mrgn-b-lg">
                    <div class="row">
                      <div class="prtm-block-title col-md-12 col-lg-12 col-xs-12">
                        <h3 class="text-capitalize">{{'Travels And Activites'|translate}}</h3>
                      </div>
                      <div class="col-md-12 col-lg-12 col-xs-12">
                        <h2>{{'NumberOfActivities'|translate}}</h2> <span class="">{{totalExpertActivities}}</span>
                      </div>
                      <div class="col-md-12 col-lg-12 col-xs-12">
                        <h2>{{'NumberOfTrips'|translate}}</h2> <span class="">{{totalExpertPackages}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-lg-12 col-xs-12">
                  <div class="main-box bookings mrgn-b-lg">
                    <div class="row">
                      <div class="prtm-block-title col-md-12 col-lg-12 col-xs-12">
                        <h3 class="text-capitalize">{{'Bookings'|translate}}</h3>
                      </div>
                      <div class="col-md-12 col-lg-12 col-xs-12">
                        <h2>{{'TotalExpertActivitiesBooks'|translate}}</h2> <span
                          class="">{{totalExpertActivitiesBooks}}</span>
                      </div>
                      <div class="col-md-12 col-lg-12 col-xs-12">
                        <h2>{{'TotalExpertActivitySales'|translate}}</h2> <span class="">{{totalExpertActivitySales}}
                          SR</span>
                      </div>
                      <div class="col-md-12 col-lg-12 col-xs-12">
                        <h2>{{'TotalExpertPackagesBooks'|translate}}</h2> <span
                          class="">{{totalExpertPackagesBooks}}</span>
                      </div>
                      <div class="col-md-12 col-lg-12 col-xs-12">
                        <h2>{{'TotalExpertPackageSales'|translate}}</h2> <span class="">{{totalExpertPackageSales}}
                          SR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
