import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-traveller-detail",
  templateUrl: "./traveller-detail.component.html",
  styleUrls: ["./traveller-detail.component.css"],
})
export class TravellerDetailComponent implements OnInit {
  @Input() serialNo: number;
  @Input() data: any;
  formGroup: FormGroup;
  constructor(private _fb: FormBuilder) {}

  today = new Date();
  ngOnInit() {
    this.formGroup = this._fb.group({
      Id: [""],
      FirstName: ["", [Validators.required]],
      MiddleName: [""],
      FamilyName: ["", [Validators.required]],
      PassportNumber: [""],
      PassportExpiry: [""],
      DOB: [""],
      Gender: ["1"],
    });
    if (this.data) this.formGroup.patchValue(this.data);

    if (this.data) {
      // debugger
      if (this.data.DOB) {
        var date = this.data.DOB.toString().split("-");
        var newDate = date[1] + "/" + date[2].substring(0, 2) + "/" + date[0];
        this.formGroup.get("DOB").setValue(newDate); //new Date(newDate);
      }

      if (this.data.PassportExpiryDate) {
        // debugger
        var date = this.data.PassportExpiryDate.toString().split("-");
        var newDate = date[1] + "/" + date[2].substring(0, 2) + "/" + date[0];
        this.formGroup.get("PassportExpiry").setValue(newDate);
      }
    }
  }
  IsFormValid() {
    this.formGroup.controls["FirstName"].markAsTouched();
    this.formGroup.controls["MiddleName"].markAsTouched();
    this.formGroup.controls["FamilyName"].markAsTouched();
    this.formGroup.controls["PassportNumber"].markAsTouched();
    this.formGroup.controls["PassportExpiry"].markAsTouched();
    this.formGroup.controls["DOB"].markAsTouched();
    this.formGroup.controls["Gender"].markAsTouched();
    return this.formGroup.valid;
  }

  GetFormValues() {
    return this.formGroup.value;
  }
}
