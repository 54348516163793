import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CustomTrip } from "../models/CustomTrip";
import { Server } from "../server";
import { CustomCountry } from "../models/CutomCountry";
import { CustomTripTheme } from "../models/CustomTripTheme";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomTripService {
  /**
   *
   */
  httpOptions = { headers: { "Content-Type": "application/json" } };
  constructor(private _http: HttpClient, private _server: Server) {}

  GetAllByPaging(
    page: number,
    pageSize: number,
    search: string,
    AgencyId: number
  ) {
    // debugger
    var url =
      environment.API_URL +
      "/api/customtrip/paging/all?page=" +
      page +
      "&page_size=" +
      pageSize;
    if (search) {
      url = url + "&search=" + search;
    }
    if (AgencyId) {
      url = url + "&agency=" + AgencyId;
    }
    return this._server.Get(url);
  }

  Add(data: CustomTrip) {
    // debugger
    const url = environment.API_URL + "/api/customtrip/create";
    //const url='http://trippat.com/api/customtrip/create'
    return this._http.post<CustomTrip>(url, data);
  }

  AddCountries(data: any) {
    const url = environment.API_URL + "/api/customtrip/createTripCountries";
    return this._http.post<CustomCountry>(url, data);
  }

  AddThemes(data: any) {
    const url = environment.API_URL + "/api/customtrip/createTripThemes";
    return this._http.post<CustomTripTheme>(url, data);
  }

  GetById(id: number) {
    const url = environment.API_URL + "/api/customtrip/";
    return this._http.get(url + id);
  }

  GetByUserId(id: number, page_size: number, page: number) {
    // debugger
    const url =
      environment.API_URL +
      "/api/customtrip/getby/user?id=" +
      id +
      "&page_size=" +
      page_size +
      "&page=" +
      page;
    return this._http.get(url);
  }

  //service when custom trip not created by admin
  GetByCustomUserId(id: number, page_size: number, page: number) {
    // debugger
    const url =
      environment.API_URL +
      "/api/customtrip/fatchby/userid?id=" +
      id +
      "&page_size=" +
      page_size +
      "&page=" +
      page;
    return this._http.get(url);
  }

  update(model: any) {
    // debugger
    const url = environment.API_URL + "/api/customtrip/update";
    return this._http.post(url, model);
  }
}
