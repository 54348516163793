import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { PackageService } from "../services/package.service";
import { FormGroup, FormControl } from "@angular/forms";
import { Package } from "../models/package";
import { List } from "../models/list";
import { Country } from "../models/classes/country";
import { Data } from "../models/data";
import { CountryService } from "../services/country.service";
import { CityService } from "../services/city.service";
import { City } from "../models/classes/city";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatFormFieldModule,
  MatFormFieldControl,
} from "@angular/material/form-field";

import { MatAutocompleteSelectedEvent, MatAutocomplete } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import * as moment from "moment";
import { SessionManagement } from "../session-management";
import { Favourite } from "../models/classes/favourite";
import { Service } from "../services/service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../services/user.service";
import { ActivityService } from "../services/activity.service";
import { ActivitycategorymasterService } from "../services/activitycategorymaster.service";
import { CampaignsService } from "../services/campaigns.service";
import { MetaTagsService } from "../services/meta-tags.service";
import { PageSeoService } from "../services/pageseo.service";

declare var $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  experts = [];
  expertsCount = 0;
  activities = [];
  packages = [];
  campaigns = [];
  campaignsCount = 0;
  searchResult: Observable<{}[]>;

  @ViewChild('destinationInput') private destinationInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') private matAutocomplete: MatAutocomplete;
  destinationCtr = new FormControl();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  today = new Date();

  showActivitySearch = false;
  showPackageSearch = true;

  activitiesCats = [];

  destinations = [];
  searchDate = this.today;
  selectedCategory = 0;
  adults = 2;
  children = 0;
  duration = 0;

  constructor(
    private packageService: PackageService,
    private countryService: CountryService,
    private cityService: CityService,
    private router: Router,
    private toastr: ToastrService,
    private service: Service,
    private translate: TranslateService,
    private user: UserService,
    private activity: ActivityService,
    private pack: PackageService,
    private activitiesCategories: ActivitycategorymasterService,
    private campaignsService: CampaignsService,
    private metaTagsService: MetaTagsService,
    private pageSeoService: PageSeoService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.getPackages();
    this.getActivities();
    this.getExperts();
    this.getActivitiesCategories();
    this.listenForSearchInput();
    this.getCampaigns();
    this.getSEOData();
  }

  getActivitiesCategories() {
    this.activitiesCategories.GetAll().subscribe((res: any) => {
      this.activitiesCats = res;
    });
  }

  listenForSearchInput() {
    this.destinationCtr.valueChanges.subscribe((data) => {
      this.countryService
        .searchInCountriesCities(data, 1, 100)
        .subscribe((data: any) => {
          if (data.count) {
            this.searchResult = data.data;
          }
        });
    });
  }

  getPackages() {
    this.pack.specialPackages(1, 6).subscribe((data: any) => {
      if (data.count) {
        this.packages = data.data;
      }
    });
  }

  getActivities() {
    this.activity.feturedActivity(1, 6).subscribe((data: any) => {
      if (data.count) {
        this.activities = data.data;
      }
    });
  }

  getExperts() {
    this.user.specialExperts(1, 6).then((data: any) => {
      if (data.count) {
        this.expertsCount = data.count;
        this.experts = data.data;
      }
    });
  }

  getCampaigns() {
    this.campaignsService.getAll(1, 6).subscribe((result: any) => {
      this.campaigns = result.data;
      this.campaignsCount = result.count;
    });
  }
  getSEOData() {
    this.pageSeoService.GetSeoByURL().subscribe((result: any) => {
      result && this.setSEOData(result);
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.destinations.push(event.option.value);
    this.destinationInput.nativeElement.value = "";
    this.destinationCtr.setValue(null);
  }

  remove(id: number): void {
    const index = this.destinations.map((m) => m.Key).indexOf(id);
    this.destinations.splice(index, 1);
  }

  activeTap(prop, not) {
    this[prop] = true;
    this[not] = false;
  }

  countControl(prop, val) {
    if (val < 0 && this[prop] == 0) {
      return;
    }
    this[prop] = this[prop] + val;
  }

  search() {
    const params = {
      page: 1,
      page_size: 10,
    };
    if (this.selectedCategory) {
      params["categoryId"] = this.selectedCategory;
    }
    if (this.adults) {
      params["adults"] = this.adults;
    }
    if (this.children) {
      params["children"] = this.children;
    }
    if (this.searchDate) {
      params["date"] = new Date(this.searchDate).toJSON();
    }
    if (this.duration) {
      params["duration"] = this.duration;
    }
    const countries = [];
    const cities = [];
    this.destinations.map((dest) => {
      if (dest.Type === "City") {
        cities.push(dest.Id);
      }
      if (dest.Type === "Country") {
        countries.push(dest.Id);
      }
      return dest;
    });
    if (countries.length) {
      params["countries"] = countries.join();
    }
    if (cities.length) {
      params["cities"] = cities.join();
    }
    if (this.showActivitySearch) {
      this.router.navigate(["/activitypannel"], {
        queryParams: { ...params },
      });
    } else {
      this.router.navigate(["/offers"], {
        queryParams: { ...params },
      });
    }
  }
  setSEOData(data) {
    this.metaTagsService.setDescription(data.MetaDescription);
    this.metaTagsService.setTitle(data.MetaTitle || "Trippat")
    data.MetaDescription && this.metaTagsService.setMetaByName("description", data.MetaDescription);
    data.MetaDescription && this.metaTagsService.setMetaByName("keywords", data.MetaDescription);
    data.MetaRobots && this.metaTagsService.setMetaByName("robots", data.MetaRobots);
    this.metaTagsService.setMetaByName("twitter:title", data.MetaTitle || "Trippat");
    data.MetaDescription && this.metaTagsService.setMetaByName("twitter:description", data.MetaDescription);
    // this.Activity.ActivityImages[0].ImagePath && this.metaTagsService.setMetaByName("twitter:image", this.Activity.ActivityImages[0].ImagePath);
    this.metaTagsService.setMetaByProperty("og:title", data.MetaTitle || "Trippat");
    // this.Activity.ActivityImages[0].ImagePath && this.metaTagsService.setMetaByProperty("og:image", this.Activity.ActivityImages[0].ImagePath);
    data.MetaDescription && this.metaTagsService.setMetaByProperty("og:url", window.location.href);
    data.MetaDescription && this.metaTagsService.setMetaByProperty("og:description", data.MetaDescription);
    data.MetaCanonical && this.setCanonicalLink(data.MetaCanonical);
    // data.MetaDescription && this.metaTagsService.setMetaByProperty("og:site_name", data.MetaDescription);
  }
  setCanonicalLink(metaCanonical: string) {
    const canonicalLink = this.renderer.createElement('link');
    if (metaCanonical) {
      this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
      this.renderer.setAttribute(canonicalLink, 'key', 'canonical');
      this.renderer.setAttribute(canonicalLink, 'href', metaCanonical);
      this.renderer.appendChild(document.head, canonicalLink);
    }
  }
}
