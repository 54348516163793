import { IActivity } from './interfaces/IActivity';
import { Country } from './classes/country';
import { City } from './classes/city';
import { ActivityCategories } from './classes/activCategory';
import { ActivityIncludes } from './classes/activInclude';
import { ActivityImage } from './classes/ActivityImage';
import { User } from './user';
import { ActivityCategoryMaster } from './classes/activityCategoryMaster';

export class Activity implements IActivity {
  Id?: number;
  Name: string;
  CityId: number;
  CountryId: number;
  AdultPrice: number;
  ChildPrice: number;
  Offer: string;
  Duration: string;
  Description: string;
  MeetingPoint: string;
  IsActive: boolean;
  IsFeatured: boolean;
  Country: Country;
  City: City;
  StatusId: number;
  AgencyId: number;
  CreatedOn: string;
  UpdatedOn: string;
  UserId: number;
  // Extra Props
  Highlight?: string;
  MeetingPointMapLink: string;
  KnowBeforeYouGo: string;

  ActivityCategories: ActivityCategories[];
  ActivityIncludes: ActivityIncludes[];
  ActivityImages: ActivityImage[];
  User: User;

  MetaTitle: string;
  MetaDescription: string;
  MetaRobots: string;
  MetaKeywords: string;
  MetaCanonical: string;
  Slug: string;

}
