import { IPackage } from "./interfaces/IPackage";
import { Country } from "./classes/country";
import { PackageImage } from "./PackageImage";
import { PackageHotel } from "../packages/package.hotel";
import { PackageActivity } from "../packages/package.activity";
import { PackageService } from "../packages/package.service";
import { City } from "./classes/city";
import { Facility } from "./classes/facility";
import { MealPlan } from "./classes/mealplan";
import { PackageCategoryMaster } from "./classes/packageCategoryMaster";
import { Favourite } from "./classes/favourite";
import { PackageCountry } from "./classes/packageCountry";
import { CustomTrip } from "./CustomTrip";

export class Package implements IPackage {
  Id?: number;
  Name: string;
  Description: string;
  AdultPrice: number;
  ChildPrice: number;
  InfantPrice: number;
  StatusId: number;
  AgencyId: number;
  PackageRating: number;
  MealPlanId: number;
  Nights: number;
  MinNumberOfAdultTravellers: number;
  Cost: number;
  Stay: number;
  Country: string;
  CountryId: number;
  StartDate: string;
  ExpiryDate: string;
  CreatedOn: string;
  UpdatedOn: string;
  UserID: number;
  // AgencyId:number;
  CustomerID: number;
  IsPublic: boolean;
  CustomTripID: number;
  IsFeatured: boolean;
  IsActive: boolean;
  SinglePrice: number;
  DoublePrice: number;
  ExtraPrice: number;
  GroupDepartureDate: string;
  DepartureType: boolean;
  MinNumberOfGroup: number;
  MaxNumberOfGroup: number;
  GroupGender: number;
  MinAge: number;
  AllowChildren: boolean;
  AllowAdult: boolean;
  IsGroup: boolean;
  Offer: number;
  IsGroupPackage: boolean;
  TotalTrevler: number;
  Category: PackageCategoryMaster[];
  PackageHotels: PackageHotel[];
  PackageActivities: PackageActivity[];
  PackageServices: PackageService[];
  PackageImages: PackageImage[];
  Countries: Country[];
  Cities: City[];
  PackageFacilities: Facility[];
  MealPlans: MealPlan[];
  PackageCategoryMasters: PackageCategoryMaster[];
  SelectedFacilityId: number[];
  SelectedMealPlanId: number[];
  SelectedCityId: number[];
  SelectedCountryId: number[];
  SelectedCategoryId: number[];
  Favourite: Favourite;
  PackageCountries: PackageCountry[];
  CustomTrip: CustomTrip;
  FilledSheat: any;
  UserBookings: any;
  AvailableSheet: any;
  AvailablePercent: any;
  OldPrice: any;
  GroupAllowMale: boolean;
  GroupAllowFemale: boolean;
  GroupAllowChildren: boolean;
  GroupAllowUnderAge: boolean;
  MetaTitle: string;
  MetaDescription: string;
  MetaRobots: string;
  MetaKeywords: string;
  MetaCanonical: string;
  Slug: string;
  GroupAvailableSheet: number;
}
