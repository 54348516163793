import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Activity } from "../models/Activity";
import { Service } from "./service";
import { PostWithUserAuth } from "../models/rest/post-user-auth";
import { UpdateUserAuth } from "../models/rest/update-user-auth";
import { FormGroup, FormControl } from "@angular/forms";
import { City } from "../models/classes/city";
import { Server } from "../server";
import { Data } from "../models/data";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CityService {
  constructor(private _http: HttpClient, private _server: Server) {}

  baseUrl = environment.API_URL + "/api/city";

  GetAll() {
    return this._http.get(this.baseUrl);
  }

  GetAllByPaging(page: number, page_size: number) {
    // debugger

    return this._http.get(
      this.baseUrl + "/paging/all?page=" + page + "&page_size=" + page_size
    );
  }

  GetCitiesByCountry(id: number) {
    return this._http.get(this.baseUrl + "/countryid/" + id);
  }
  GetCitiesByCountries(countries: any) {
    return this._http.get(this.baseUrl + "/countries?countries=" + countries);
  }

  GetByName(city: string) {
    return this._http.get(this.baseUrl + "/byname?name=" + city);
  }

  GetCityById(id: number) {
    return this._http.get(this.baseUrl + "/cityId/" + id);
  }

  Add(model: City) {
    model.IsActive = true;
    return this._server.Post(environment.API_URL + "/api/city/create", model);
  }
  Update(id: number, model: City) {
    return this._server.Post(
      environment.API_URL + "/api/city/update/" + id,
      model
    );
  }
  GetCity(cityId: number) {
    return this._server.Get(environment.API_URL + "/api/city/" + cityId);
  }

  Delete(cityId: number) {
    return this._server.Post(
      environment.API_URL + "/api/city/delete/" + cityId,
      null
    );
  }

  IsCityExist(name: string, id: number) {
    // debugger
    let promise = new Promise<{ [key: string]: any } | null>(
      (resolve, reject) => {
        this._http
          .post(environment.API_URL + "/api/city/cityexist", {
            Name: name,
            Id: id,
          })
          .subscribe((data: Data<string>) => {
            if (data.returnCode == 0) {
              resolve(null);
            } else {
              resolve({ exist: true });
            }
          });
      }
    );
    return promise;
  }

  GetCitiesById(cities: any) {
    // debugger
    const url =
      environment.API_URL + "/api/city/getby/cities?citiesID=" + cities;
    return this._http.get(url);
  }

  TripCites(cityids: any) {
    // debugger
    //this.baseUrl="http://trippat.com";
    var url = this.baseUrl + "/gettripcity/Name?cityids=" + cityids;
    return this._http.get(url);
  }
}
