import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "../models/user";
import { Server } from "../server";
import { Data } from "../models/data";
import { PackageSearch } from "../models/package-search";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private _server: Server, private http: HttpClient) {}
  ipAddress: any;

  GetAll(page: number, pageSize: number, search: string, AgencyId: number) {
    // tslint:disable-next-line:triple-equals
    var url =
      environment.API_URL + "/api/user?page=" + page + "&page_size=" + pageSize;
    if (search.length > 0) {
      url = url + "&search=" + search;
    }
    if (AgencyId) {
      url = url + "&agency=" + AgencyId;
    }
    return this._server.Get(url);
  }

  GetAllByAgency(
    page: number,
    pageSize: number,
    search: string,
    Agency: number
  ) {
    // debugger;
    var url =
      environment.API_URL + "/api/user?page=" + page + "&page_size=" + pageSize;
    if (search.length > 0) {
      url = url + "&search=" + search;
    }
    if (Agency) {
      url = url + "&agency=" + Agency;
    }
    return this._server.Get(url);
  }

  GetUserById(id: number) {
    return this._server.Get(environment.API_URL + "/api/user/" + id);
  }

  GetUserByEncriptedId(EncriptId: number) {
    // debugger;
    return this._server.Get(
      environment.API_URL + "/api/user/Encripteduser/" + EncriptId
    );
  }

  GetUserByEmail(Email: any) {
    // debugger;
    return this._server.Get(
      environment.API_URL + "/api/user/finduser/" + Email
    );
  }

  specialExperts(page, pageSize) {
    return this._server.Get(
      `${environment.API_URL}/api/user/special/experts?page=${page}&page_size=${pageSize}`
    );
  }

  Add(model: any) {
    model.IsActive = true;
    return this._server.Post(environment.API_URL + "/api/user/create", model);
  }

  Activate(model: any) {
    return this._server.Post(
      environment.API_URL + "/api/user/activate/",
      model
    );
  }

  Approve(model: any) {
    // debugger;
    return this._server.Post(environment.API_URL + "/api/user/approve/", model);
  }

  Block(model: any) {
    return this._server.Post(environment.API_URL + "/api/user/block/", model);
  }

  DeActivate(model: any) {
    return this._server.Post(
      environment.API_URL + "/api/user/deactivate/",
      model
    );
  }
  Verify(id: number) {
    return this._server.Get(environment.API_URL + "/api/user/verify/" + id);
  }

  Update(id: number, model: User) {
    return this._server.Post(environment.API_URL + "/api/user/update", model);
  }

  Updatedata(model: User) {
    // debugger;
    return this._server.Post(
      environment.API_URL + "/api/user/updateuser",
      model
    );
  }

  UpdateUserEncriptedData(model: any) {
    // debugger;
    return this._server.Post(
      environment.API_URL + "/api/user/EncriptUserUpdate",
      model
    );
  }

  UpdateUserProfile(id: number, model: any) {
    // debugger;
    return this._server.Post(
      environment.API_URL + "/api/user/userprofile/" + id,
      model
    );
  }
  ChangePassword(id: number, model: any) {
    // debugger;
    return this._server.Post(
      environment.API_URL + "/api/user/changepassword/" + id,
      model
    );
  }

  Updatepassword(model) {
    return this._server.Post(
      environment.API_URL + "/api/user/updatepassword",
      model
    );
  }

  ChangeRole(id: number, roleid: number) {
    return this._server.Get("api/user/changerole/" + id + "/" + roleid);
  }
  IsTokenExist(token: string) {
    return this._server.Get(
      environment.API_URL + "/api/user/tokenexist/?token=" + token
    );
  }
  GetUserByToken(token: string) {
    // debugger;
    return this._server.Get(
      environment.API_URL + "/api/user/token?token=" + token
    );
  }

  ForgotPassword(Email: any) {
    return this._server.Post(
      environment.API_URL + "/api/user/forgotpassword/",
      { Email }
    );
  }

  GetUserByCode(code: string) {
    return this._server.Get(
      environment.API_URL + "/api/user/code?code=" + code
    );
  }
  ResendLink(id: number) {
    return this._server.Get(environment.API_URL + "/api/user/resendlink/" + id);
  }
  ResetPassword(model) {
    return this._server.Post(
      environment.API_URL + "/api/user/resetpassword",
      model
    );
  }
  CheckCaptcha(model: any) {
    return this._server.Post(environment.API_URL + "/api/user/captcha", model);
  }
  Login(model: any) {
    return this._server.Post(environment.API_URL + "/api/user/login", model);
  }
  SocialLogin(model: any) {
    return this._server.Post(
      environment.API_URL + "/api/user/sociallogin",
      model
    );
  }
  UpdateProfilePicture(model: any) {
    return this._server.Post(
      environment.API_URL + "/api/user/profilepicture",
      model
    );
  }
  ChangeName(model: any) {
    return this._server.Post(
      environment.API_URL + "/api/user/changename",
      model
    );
  }
  IsEmailExist(email: string, id: number) {
    let promise = new Promise<{ [key: string]: any } | null>(
      (resolve, reject) => {
        this._server
          .Post(environment.API_URL + "/api/user/emailexist", {
            Email: email,
            Id: id,
          })
          .then((data: Data<string>) => {
            if (data.returnCode == 0) {
              resolve(null);
            } else {
              resolve({ exist: true });
            }
          });
      }
    );
    return promise;
  }
  GetPackagesFilter(model: PackageSearch) {
    // return this._server.G
  }

  CountUser() {
    return this._server.Get(
      environment.API_URL + "/api/user/count/verifiedandregistered"
    );
  }

  //agency Register

  AddAgency(model: User) {
    model.IsActive = true;
    return this._server.Post(
      environment.API_URL + "/api/user/createagency",
      model
    );
  }

  //Exist Agency Register
  AddExistAgency(model: User) {
    model.IsActive = true;
    return this._server.Post(
      environment.API_URL + "/api/user/existagency",
      model
    );
  }

  upgraderole(UserId, RoleId) {
    // debugger;
    return this._server.Post(environment.API_URL + "/api/user/upgraderole/", {
      UserId,
      RoleId,
    });
  }

  blockUser(userId) {
    return this._server.Post(
      `${environment.API_URL}/api/user/blockuserfromadmin/${userId}`,
      {
        userId,
      }
    );
  }

  unBlockUser(userId) {
    return this._server.Post(
      `${environment.API_URL}/api/user/unblockuserfromadmin/${userId}`,
      { userId }
    );
  }

  activeUser(userId) {
    return this._server.Post(
      `${environment.API_URL}/api/user/activeuserfromadmin/${userId}`,
      { userId }
    );
  }

  googleLogin(idToken) {
    const body = {
      idToken,
    };
    return this.http.post(environment.API_URL + "/api/user/login/google", body);
  }

  faceBookLogin(idToken) {
    const body = {
      idToken,
    };
    return this.http.post(
      environment.API_URL + "/api/user/login/facebook",
      body
    );
  }
}
