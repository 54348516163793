<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pad-r-none">
      <div class="settings-side-bar">
        <ul class="nav nav-pills nav-stacked setting-sidebar">
          <!-- <li><a [routerLink]="['/user/changepassword']"><i class="fas fa-unlock-alt"></i> Change password</a>
              </li> -->
          <!-- <li><a [routerLink]="['/qualityandassurance']"><i class="far fa-comment-dots"></i> Q&amp;A </a>
          </li> -->
          <!-- <li><a [routerLink]="['/contactus']"><i class="far fa-envelope"></i> Contact
                      us</a></li> -->
          <li><a [routerLink]="['/privacy-policy']" class="active"><i class="fas fa-clipboard-check"></i>سياسة
              الخصوصية</a></li>
          <li><a [routerLink]="['/termandcondition']"><i class="fas fa-clipboard-check"></i>الشروط
              والاحكام</a></li>
        </ul>
      </div>
    </div>
    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9 pt-70 pb-150 pad-l-lg bg-white">
      <h3 class="mrgn-b-md">سياسة الخصوصية</h3>
      <br>
      <!-- start -->
      جمع المعلومات واستخدامها
      تقع على عاتقنا مهمة حماية معلوماتك الشخصية، ويتم توضيح هذه السياسة وكيفية استخدام معلوماتك الشخصية عبر موقع
      www.trippat.com، والتطبيق المتوافر على الأجهزة الذكية أيفون، أندرويد، ويندوز (المشار إليها جميعا في الموقع).
      علماً بأن سياسة الخصوصية هذه لا تنطبق على مواقع شركائنا في العمل، الشركات التابعة، أو أي أطراف أخرى، وحتى إن تم
      الإشارة إليهم على الموقع. لذا ننصحك بمراجعة سياسة الخصوصية العائدة للأطراف الأخرى التي تود التعامل معها.
      عند استخدامك للخدمات المتوفرة على الموقع أو تطبيقات الموبايل، سيتم طلب تقديم بعض المعلومات مثل اسمك و عناوين
      الاتصال يتم تخزين هذه المعلومات والاحتفاظ بها على أجهزة الكمبيوتر أو بصيغة آمنة ومشفرة.
      <br>
      <br>
      <b>ماهي المعلومات التي سنقوم بجمعها؟</b>

      <ul>
        <li> معلومات شخصية تتضمن بريدك الإلكتروني واسمك ورقم هاتفك ورقم تعريف جهازك وعنوان بروتوكول الإنترنت.</li>
        <li> معلومات المعاملة، ومنها تفاصيل برنامج الرحلة.</li>
        <li> معلومات الحجز، ومنها الوجهات والتواريخ.</li>
        <li>معلومات أخرى، ومنها معلومات عن تصفحك للموقع أو التطبيق.
          سنقوم باستخدام هذه المعلومات بالطرق التالية</li>
        <li>الوفاء باتفاقنا معك بما في ذلك تجهيز الرحلات وحجوزات الفنادق والباقة، وإرسال مسار الرحلة الخاصة بك، أو
          التواصل
          معك في حال وجود أي مشكلة تتعلق بالحجز الخاص بك.</li>
        <li>لتسجيل اسمك على موقعنا أو تطبيقات الموبايل وبالتالي يمكنك إدارة حسابك على موقعنا لتلقي جميع خدماتنا. كما
          يمكنك
          إلغاء الاشتراك عبر التواصل معنا في حال لم تعد ترغب بالتمتع بهذه الخدمات.</li>
        <li>للإجابة على أي استفسار قمت بإرساله إلينا عبر البريد الالكتروني.</li>
        <li>لأغراض التسويق المباشر، على النحو المبين بالتفصيل أدناه.
          من الضروري لدينا معرفة جميع أسماء الركاب المسافرين أو الأسماء المرتبطة بالحجوزات. إذا كنت قد قمت بالحجز
          بالنيابة
          عن شخص آخر، فمن المفترض أن تكون قد حصلت على موافقته لاستخدام معلوماته الشخصية. وسوف نكمل الإجراءات بناءً على
          الموافقة المذكورة أعلاه.</li>
      </ul>
      <br>
      <b>بماذا سيتم استخدام هذه المعلومات؟ مع إضافات سيتم ادراجها</b>

      <ul>
        <li>لإجراء الحجوزات، شراء التذاكر، ومعاملات الدفع في الخدمات المتوفرة على الموقع.</li>
        <li>لمنحك تجربة خاصة وتقديم أفضل الخدمات ( والعمل على تلبية احتياجاتك الفردية على أفضل وجه عبر تزويدنا بمعلوماتك
          الشخصية)</li>
        <li>لتطوير أداء استخدام الموقع: ( نسعى دوماً لتحسين خدمات الموقع استناداً على المعلومات التي نستلمها منك)</li>
        <li>لتحسين خدمة العملاء (هذه المعلومات تساعدنا على الإجابة بشكل أفضل على طلباتك الموجهة لفريق خدمة العملاء ودعم
          احتياجاتهم)و تحليل حركة البيانات على الموقع.</li>
        <li>لإدارة أي مسابقة أو عرض ترويجي، أو استطلاع إحصائي أو إحدى ميزات الموقع الأخرى.</li>
        <li>لإرسال رسائل إلى البريد الإلكتروني أو رسائل قصيرة، إشعارات للمستخدمين عبر الموقع أو التطبيق أو غيرها من
          الوسائل المتاحة لتقديم كافة الخدمات، والإجابة على جميع الاستفسارات والطلبات، وغيرها من الأسئلة.</li>
        <li>للاستجابة على استفسارات السلطات فيما يتعلق باستخدامك للموقع والخدمات المتاحة إليك.</li>
        <li>التسجيل التلقائي لدخول البيانات.</li>
      </ul>
      بالتأكيد، أنت المسؤول الوحيد عن سرية كلمة المرور الخاصة بك، ومعلومات حسابك الشخصي. لذا نرجو منك الحرص على الحفاظ
      على هذه المعلومات لاسيما عندما تكون متصلاً بالإنترنت.

      <br>
      <b>كيف نقوم باستخدام معلوماتك الشخصية؟</b>
      <br>
      سنقوم باستخدام جميع المعلومات المقدمة من طرفك أو التي استطعنا الحصول عليها لتطوير وتحسين الخدمات المقدمة إليك
      ولجميع عملائنا وتزويدك بآخر الأخبار عن تطبيقاتنا الجديدة، الخدمات والعروض الخاصة والمميزة عبر (البريد أو البريد
      الإلكتروني أو الهاتف أو غير ذلك). وقد نقوم في بعض الأحيان بإعلامك عن المنتجات الجديدة والخدمات والعروض الخاصة
      بأطراف أخرى محدّدة، في حال قمت خلال عملية التسجيل بالموافقة على خيار تلقّي نشرة تريبات الإلكترونية وآخر
      التحديثات.

      <br>
      <br>

      <b>ما الجهات التي نقوم بمشاركة معلوماتك معها؟</b>
      <br>
      <b>من الممكن أن نقوم بمشاركة معلوماتك على النحو التالي:</b>
      <ul>
        <li>لجميع فروع مكاتب فريق خدمة العملاء.</li>
        <li>لأطراف أخرى جديرة بالثقة والتي نقوم بالتعامل معها لتوفير خدمات معينة مثل :الإتاحة لعملائنا بحجز رحلات طيران
          أو
          إتمام عملية رحلات تم حجزها أواستئجار سيارات أو حجز فندق أو إتمام حجز رحلة سياحية أو غيرها من الخدمات بسرعة
          وسهولة.</li>
        <li>للجمارك ودائرة الهجرة أو غيرها من السلطات التنظيمية في بلد المغادرة أو الوجهة من أجل الامتثال للقانون في تلك
          البلدان.</li>
        <li>لأطراف أخرى مشاركة في سياسة ترخيص بطاقة الائتمان.</li>
        <li>إذا كان لدينا مهمة للقيام بذلك أو إذا كان القانون يسمح لنا بذلك.</li>
        <li>لموظفينا ووكلائنا للقيام بإحدى المهام التي قمنا بالإشارة إليها أعلاه، الآن أو في المستقبل.</li>
        <li>للمؤسسات الشريكة التي هي من ضمن شركتنا وشركتنا الأم وأخرى تابعة لها، حيث تمكننا هذه المشاركة من ان نقدم لك
          معلومات حول خدمات ومنتجات، ذات صلة بالرحلات وغيرها والتي قد تثير اهتمامك. وستكون لدى هذه الشركات صلاحية محدّدة
          للاطلاع على معلوماتك الخاصة طبقاّ لما ورد ذكره في سياسة الخصوصية. كما ستقوم هذه الشركات بالامتثال للقوانين
          المعمول بها التي تنظمها وسائل الاتصالات، والتي تمنحك على أقل تقدير الفرصة لاختيار عدم استلام رسائل مشابهة عبر
          بريدها الإلكتروني التجاري في المستقبل.</li>
      </ul>

      إذا قمت باختيار عدم مشاركتنا لبعض البيانات الشخصية المطلوبة، سيكون بمقدورك زيارة صفحتنا لكنك قد لا تتمكن من
      الوصول لبعض الخيارات أو الخدمات.
      نقل بياناتك الشخصية
      في سياق التعهد بالالتزام بأنشطة محدّدة في سياسة الخصوصية، من الممكن أن نقوم بإرسال معلومات شخصية الى البلدان
      التي ليس لديها قوانين حماية البيانات أو البلدان حيث الخصوصية فيها والحقوق الأساسية الأخرى غير محمية على نطاق
      واسع. سوف نقوم بمنح المعلومات فقط بغرض تقديم واستيفاء جميع الخدمات التي قمت بطلبها منا، من أجل إتمام التزامنا مع
      الأطراف الأخرى لأجل خدمتك، أو الكشف عن المعلومات التي يتطلب منا القيام بها بموجب القانون أو بشكل منتظم.

      <br>
      <br>
      <b>ما الذي تحتاج معرفته عند استخدامك تطبيق تريبات عبر جوالك أو جهازك اللوحي</b>
      <br>

      عند استخدامك لتطبيقات الموبايل، نقوم بجمع واستخدام المعلومات عنك بنفس الطريقة ولنفس الأغراض التي نقوم بها عند
      استخدامك لموقعنا.
      نقوم أيضا بإضافة المعلومات التي نجمعها عند استخدام موقع تريبات، واستعمال بعض المعلومات الأخرى التي نقوم بجمعها
      تلقائياً لدى استعمالك لتطبيقاتنا على الموبايل. على وجه التحديد:
      <ul>
        <li>
          نقوم وبشكل تلقائي بجمع المعلومات حول تطبيق الهاتف الجوال، والتي يمكنك الوصول إليها واستخدامها. مما يسمح لنا
          بتحديد مجالات اهتمام عملائنا على تطبيقات الهاتف الجوال، ولكي لنتمكن من تطويرها وتحسينها بشكل دائم. ولا تمكننا
          هذه المعلومات التي قمنا بجمعها لهذا الغرض من التعرف عليك بشكل مباشر. يرسل كل تطبيق للهاتف الجوال إلينا معرف
          جهاز
          فريد من نوعه (أو "UDI")، وهو عبارة عن سلسلة من الأرقام أو الأحرف الفريدة لهاتفك الجوال، بالإضافة إلى معرف جهاز
          فريد من نوعه للمعلنين (أو "IDFA")، ومعرف جهاز فريد من نوعه للمزودين(أو "IDFV"). ونقوم باستخدامها فقط عند أول
          استخدام لتطبيقات الهاتف الجوال لتأكيد نتائج أعداد تنزيل البيانات التي تتم من عملية النقر على لافتاتنا
          الإعلانية
          وأدواتنا التسويقية، لشبكة إعلاناتنا.
        </li>
        <li>
          عند استخدامك لتطبيق الموبايل، يمكنك اختيار السماح بالوصول لموقعك الحالي - ميزة يتم توفيرها من قبل هاتفك الجوال
          عبر استخدام الـ GPS أو تقنيات مشابهة - بهدف تحديد الفنادق أو المطارات القريبة منك. إذا اخترت السماح بتفعيل هذه
          الميزة، فمن الممكن أن تقوم ذاكرة هذا الموقع وبشكل مجهول بجمع المعلومات عن موقعك كجزء من طلبات البحث التي تم
          تسجيلها من قبل خادمنا، وذلك لإيصال رسائل تسويقية ذات صلة وتحسين تجربتك. كما يمكنك إبطال تفعيل ميزة تحديد موقعك
          في تطبيق الهاتف الجوال وفي أي وقت من خلال قائمة إعدادات جوالك. ولكن قد يحد هذا من الاستفادة من الخدمات التي
          تعتمد على المكان أو الخيارات المتاحة على تطبيق الجوال لدينا.
        </li>
        <li>تستطيع بشكل مستمر التحكم بنوعية المعلومات التي يتم إرسالها عبر تطبيق الموبايل إلينا. يمكنك التحكم في هذه
          الخدمة إما عن طريق إجراء بعض التعديلات في إعدادات تطبيق الموبايل تحت عنوان قائمة الإعدادات أو تغيير إعدادات
          هاتفك الجوال. بدلاً من ذلك، يمكنك إزالة تطبيق الموبايل من جهازك المحمول كلياً والوصول لخدماتنا عبر موقعنا.
        </li>
      </ul>

      <br>
      <br>
      <b>سياستنا على الكوكيز</b>
      <br>
      هناك تقنية تدعى "الكوكيز" التي يمكننا استخدامها لنقدم إليك المعلومات التي تم تعديلها حسب الطلب من موقع تريبات أو
      تطبيقات الموبايل . الكوكيز عبارة عن عنصر من البيانات على موقع أو تطبيق الموبايل والتي من الممكن إرسالها إلى
      المتصفح الخاص بك وتخزينها على النظام الخاص بك فيما بعد.
      تسمح لنا كوكيز بالتعرف على من قام برؤية صفحاتنا وإعلاناتنا، لتحديد عدد المرات التي تمت بها زيارة صفحات معينة
      وتحديد النقاط الأكثر زيارة على موقعنا. وتسمح لنا أيضا بجعل استخدام موقع وتطبيق تريبات أكثر سهولة، على سبيل
      المثال السماح لنا بأخذك إلى اللغة التي تم استخدامها على الموقع في المرة الأخيرة، لمنحك تجربة أفضل عند إعادة
      استعمال الموقع. معظم متصفحات الويب تقوم تلقائياً بقبول الكوكيز. ولكن أنت لست مضطراً لقبول الكوكيز، إلا أنّه
      يتوجب عليك قراءة المعلومات الخاصة بمتصفح جهازك لترى كيفية إعداده لإرسال تنبيه اليك لحظة استلامك للكوكيز، وعندها
      بإمكانك القرار بقبولها أو لا.

      <!-- end -->


    </div>
  </div>
</div>
