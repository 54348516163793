import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { SocialLoginModule } from "angularx-social-login";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { ImageDescriptionComponent } from "../image-description/image-description.component";
import { MatChipsModule } from "@angular/material/chips";
import { PaginationComponent } from "../pagination/pagination.component";
import { UserLayoutComponent } from "../user-layout/user-layout.component";
import { LayoutComponent } from "../layout/layout.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AdminNewLayoutComponent } from "../admin-new-layout/admin-new-layout.component";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsModalService, ModalModule, ModalBackdropComponent } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TravellerDetailComponent } from "../traveller-detail/traveller-detail.component";
import { NgxPrintModule } from "ngx-print";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { Ng6SocialButtonModule, SocialServiceConfig } from "ng6-social-button";
import { JwSocialButtonsModule } from "jw-angular-social-buttons";
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AngularStickyThingsModule } from "@w11k/angular-sticky-things";
import { TripBoxComponent } from "../components/trip-box/trip-box.component";
import { NoTripsFoundComponent } from './no-trips-found/no-trips-found.component';
import { PaymentComponent } from './components/payment/payment.component';
import { HttpLoaderFactory } from '../app.module';
// import { ModalContainerComponent } from "ngx-bootstrap/modal";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // Ng6SocialButtonModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    // SocialLoginModule,
    MatDialogModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    NgxPrintModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    MatAutocompleteModule,
    MatProgressBarModule,
    // JwSocialButtonsModule,
    BsDropdownModule.forRoot(),
    // AngularStickyThingsModule,
    // NgxIntlTelInputModule,
    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    TranslateModule.forChild()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // SocialLoginModule,
    MatDialogModule,
    ImageDescriptionComponent,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatAutocompleteModule,
    PaginationComponent,
    TravellerDetailComponent,
    NgxPrintModule,
    MatProgressBarModule,
    // JwSocialButtonsModule,
    BsDatepickerModule,
    MatSelectModule,
    TripBoxComponent,
    TranslateModule,
    // ModalBackdropComponent,
    // ModalContainerComponent,
    // ModalModule,
    NoTripsFoundComponent
  ],
  providers: [
    BsModalService,
    //   {
    //   provide: SocialServiceConfig,
    //   useFactory: getAuthServiceConfigs,
    // }
  ],
  declarations: [
    ImageDescriptionComponent,
    LayoutComponent,
    PaginationComponent,
    UserLayoutComponent,
    AdminNewLayoutComponent,
    TravellerDetailComponent,
    TripBoxComponent,
    NoTripsFoundComponent,
    PaymentComponent,
    // ModalBackdropComponent,
    // ModalContainerComponent,
  ],
})
export class SharedModule {}
export function getAuthServiceConfigs() {
  // let config = new SocialServiceConfig()
  //   .addFacebook("465668584252484")
  //   //.addFacebook("2358790541036281")
  //   .addGoogle("802263654964-s9n2qjfuh0kgn9u1i8j9dvo1jin5l34j.apps.googleusercontent.com")
  // // .addLinkedIn("Your-LinkedIn-Client-Id");
  // return config;
}
