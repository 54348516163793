import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { MetaTagsService } from "./services/meta-tags.service";
import { StorageService } from "./shared/services/storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService
  ) {
    this.listenToRouting();
    const lang = "ar" || "en";
    this.translate.use(lang);
    this.document.documentElement.dir = lang === "en" ? "ltr" : "rtl"; // Change app dire base on lang
    this.document.body.dir = lang === "en" ? "ltr" : "rtl"; // Change app dire base on lang
  }

  ngOnInit() {
    this.gtmService.addGtmToDom();
  }

  listenToRouting() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const state = this.router.routerState;
        const parent = this.router.routerState.root;

        const title = this.getData(state, parent, "title").join("-");
        const description = this.getData(state, parent, "description").join(
          "-"
        );

        this.setData(title, description);
      }
    });
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getData(state, parent, target) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data[target]) {
      data.push(parent.snapshot.data[target]);
    }

    if (state && parent) {
      data.push(...this.getData(state, state.firstChild(parent), target));
    }
    return data;
  }

  setData(title, description) {
    // this.metaTagsService.setTitle(title);
    // this.metaTagsService.setDescription(description);
  }
}
