import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  saveDataToSession(arg0: string, data: { Children: number; Adult: number; PackageId: number; StartDate: Date; }) {
    throw new Error("Method not implemented.");
  }
  constructor() {}

  get(key) {
    return localStorage.getItem(key);
  }

  set(key, value) {
    return localStorage.setItem(key, value);
  }

  remove(key) {
    return localStorage.removeItem(key);
  }

  clear() {
    return localStorage.clear();
  }
}
