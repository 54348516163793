import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Package } from "../models/package";
import { PackageSearch } from "../models/package-search";
import { Server } from "../server";
import { throwError } from "rxjs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PackageActivity } from "../models/classes/packageActivity";
import { PostWithUserAuth } from "../models/rest/post-user-auth";
import { PackageHotel } from "../models/classes/packageHotel";
import { UpdateUserAuth } from "../models/rest/update-user-auth";
import { PackageCity } from "../models/classes/packageCity";
import { Activity } from "../models/Activity";
import { PackageCountry } from "../models/classes/packageCountry";
import { PackageCityNew } from "../models/classes/packageCityNew";
import { PackageAmenity } from "../models/classes/packageAmenity";
import { PackageExtraService } from "../models/classes/packageExtraService";
import { PackageCategory } from "../models/classes/packageCategory";
import { PackageMealPlan } from "../models/classes/packageMealPlan";
import { PackageFacility } from "../models/classes/PackageFacility";
import { PackageServiceTable } from "../models/classes/PackageServiceTable";
import { PackageImage } from "../models/PackageImage";
import { ActivatedRoute } from "@angular/router";
import { LocalService } from "./local.service";
import { environment } from "src/environments/environment";
import { StorageService } from "../shared/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class PackageService {
  sub: any;
  httpOptions = { headers: { "Content-Type": "application/json" } };
  constructor(
    private _http: HttpClient,
    private route: ActivatedRoute,
    private localservice: LocalService,
    private storageService: StorageService
  ) { }

  baseUrl = environment.API_URL + "/api/package";
  packageAddFormGroup = new FormGroup({
    Id: new FormControl(""),
    Name: new FormControl("", [Validators.required]),
    Description: new FormControl(""),
    AdultPrice: new FormControl("", [Validators.required]),
    ChildPrice: new FormControl("", [Validators.required]),
    InfantPrice: new FormControl("", [Validators.required]),
    StatusId: new FormControl(""),
    MealPlan: new FormControl(""),
    Nights: new FormControl("", [Validators.required]),
    MinNumberOfAdultTravellers: new FormControl("", [Validators.required]),
    Cost: new FormControl(""),
    Stay: new FormControl(""),
    IsActive: new FormControl(""),
    IsFeatured: new FormControl(""),
    ExpiryDate: new FormControl("", [Validators.required]),
    PackageRating: new FormControl("", [Validators.required]),
    StartDate: new FormControl("", [Validators.required]),
  });
  packageCityFormGroup = new FormGroup({
    Search: new FormControl(""),
  });
  /**
   * Will query the db and get all the packages
   * Will use the http client to directly query the database as we do not need to use the sequelize
   */
  GetAll() {
    // debugger
    return this._http.get(environment.API_URL + "/api/package/all");
  }
  GetSitemap() {
    return this._http.get(environment.API_URL + "/api/package/sitemap");
  }

  getAllByParams(params) {
    return this._http.get(
      `${environment.API_URL}/api/package/paging/all?${params}`
    );
  }

  GetByPaging(
    page: number,
    pageSize: number,
    search: string,
    expertId: number
  ) {
    // debugger
    var url =
      environment.API_URL +
      "/api/package/paging?page=" +
      page +
      "&page_size=" +
      pageSize;
    if (search) {
      url = url + "&search=" + search;
    }
    if (expertId) {
      url = url + "&expertId=" + expertId;
    }
    return this._http.get(url);
  }

  GetAllByAgency(agencyId) {
    return this._http.get(
      environment.API_URL + "/api/package/all/agency/" + agencyId
    );
  }

  specialPackages(page, pageSize) {
    // https://dev.trippat.com/api/package/special/offer?page=1&page_size=6
    return this._http.get(
      `${environment.API_URL}/api/package/special/offer?page=${page}&page_size=${pageSize}`
    );
  }

  //Delete Package
  Delete(id: number, model: Package) {
    // debugger;
    return this._http.post(
      environment.API_URL + "/api/Package/delete/" + id,
      model
    );
  }

  /**
   * Will require a PackageSearch model as param to query database accordingly
   * Will be using the Server class to query the db
   * @param {PackageSearch} packageModel
   * @memberof PackageService
   */
  GetFilteredPackages(packageModel: PackageSearch) {
    // debugger
    let url = environment.API_URL + "/api/package";
    const checkIfObjectIsEmpty = this.checkIfObjectIsEmpty(packageModel);
    if (!checkIfObjectIsEmpty) {
      url += "?" + this.MakeRequestUrlQueryParams(packageModel);
    }
    return this._http.get(url);
  }
  /**
   *
   *
   * @param {Package} packageModel Needs a PackageAdd type of model to perform the request
   * @memberof PackageService
   */
  Add(_package: Package) {
    // debugger
    const url = environment.API_URL + "/api/package";
    const httpOptions = { headers: { "Content-Type": "application/json" } };
    return this._http.post<Package>(url, _package, httpOptions);
  }
  // TODO: Perform sanitization of the values before querying the db
  SanitizeQueryParamValue(value) {
    return value;
  }

  MakeRequestUrlQueryParams(packageModel: PackageSearch) {
    let url = "";
    const and = "&";
    if (this.CheckIfValueIsPresent(packageModel.Category)) {
      url +=
        "category=" + this.SanitizeQueryParamValue(packageModel.Category) + and;
    }
    if (this.CheckIfValueIsPresent(packageModel.CostUpper)) {
      url +=
        "cost=" + this.SanitizeQueryParamValue(packageModel.CostUpper) + and;
    }
    if (this.CheckIfValueIsPresent(packageModel.City)) {
      url += "city=" + this.SanitizeQueryParamValue(packageModel.City) + and;
    }
    if (this.CheckIfValueIsPresent(packageModel.Country)) {
      url +=
        "country=" + this.SanitizeQueryParamValue(packageModel.Country) + and;
    }
    if (this.CheckIfValueIsPresent(packageModel.Facility)) {
      url +=
        "facility=" + this.SanitizeQueryParamValue(packageModel.Facility) + and;
    }
    if (this.CheckIfValueIsPresent(packageModel.Name)) {
      url += "name=" + this.SanitizeQueryParamValue(packageModel.Name) + and;
    }
    if (this.CheckIfValueIsPresent(packageModel.Stay)) {
      url += "stay=" + this.SanitizeQueryParamValue(packageModel.Stay);
    }
    return url;
  }

  CheckIfValueIsPresent(val) {
    if (val === null || val === "" || val === undefined) {
      return false;
    }
    return true;
  }

  checkIfObjectIsEmpty(object) {
    for (const prop in object) {
      if (object.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
  AddImage(model: any) {
    // debugger;
    const url = this.baseUrl + "/uploadimage";
    return this._http.post<PackageImage>(url, model, this.httpOptions);
  }

  MakeThePackageModel(activityFormGroup: FormGroup) {
    // debugger;
    const model = new Package();
    for (const key in activityFormGroup.controls) {
      if (activityFormGroup.controls.hasOwnProperty(key)) {
        model[key] = this.GetReactiveFormGroupValue(activityFormGroup, key);
      }
    }
    return model;
  }

  AddPackageActivities(
    packageActivities: PackageActivity[],
    packageId: number,
    userId: number
  ) {
    const postWithUserAuthObj = new PostWithUserAuth();
    postWithUserAuthObj.UserId = userId;
    postWithUserAuthObj.Data = packageActivities;
    const url = this.baseUrl + "/" + packageId + "/activities";
    return this._http.post(url, postWithUserAuthObj, this.httpOptions);
  }

  AddPackageHotels(
    packageHotels: PackageHotel[],
    packageId: number,
    userId: number
  ) {
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = packageHotels;
    const url = this.baseUrl + "/" + packageId + "/hotels";
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }

  UpdatePackage(packageModel: Package) {
    // debugger
    const url = this.baseUrl + "/" + packageModel.Id;
    return this._http.post<UpdateUserAuth>(url, packageModel, this.httpOptions);
  }

  UpdatePackageTrevler(model: Package) {
    // debugger
    const url = this.baseUrl + "/trevlerupdate" + model.Id;
    return this._http.post<UpdateUserAuth>(url, model, this.httpOptions);
  }

  GetPackage(packageId: number) {
    const url = environment.API_URL + "/api/package/" + packageId;

    //const url='http://trippat.com/api/package/'+packageId;
    return this._http.get(url);
  }
  GetPackageBySlug(slug: string) {
    const url = environment.API_URL + "/api/package/slug/" + slug;

    //const url='http://trippat.com/api/package/'+packageId;
    return this._http.get(url);
  }

  GetPackageHotels(packageId: number) {
    const url = this.baseUrl + "/" + packageId + "/hotels";
    return this._http.get<PackageHotel[]>(url);
  }

  GetPackageActivities(packageId: number) {
    const url = this.baseUrl + "/" + packageId + "/activities";
    return this._http.get<PackageActivity[]>(url);
  }

  SetupActivityFormGroupFields(
    activityFormGroup: FormGroup,
    packageModel: Package
  ) {
    for (const key in packageModel) {
      if (packageModel.hasOwnProperty(key)) {
        const element = packageModel[key];
        if (element) {
          this.SetFormGroupControlNameValue(activityFormGroup, key, element);
        }
      }
    }
  }

  UpdatePackageActivities(
    packageId: number,
    packageActivities: PackageActivity[],
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/activities";
    const updateWithUserAuth = new UpdateUserAuth();
    updateWithUserAuth.UserId = userId;
    updateWithUserAuth.Update = true;
    updateWithUserAuth.Data = packageActivities;
    return this._http.post<UpdateUserAuth>(
      url,
      updateWithUserAuth,
      this.httpOptions
    );
  }

  UpdatePackageHotels(
    packageId: number,
    packageHotels: PackageHotel[],
    useId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/hotels";
    const updateUserAuth = new UpdateUserAuth();
    updateUserAuth.UserId = useId;
    updateUserAuth.Update = true;
    updateUserAuth.Data = packageHotels;
    return this._http.post<UpdateUserAuth>(
      url,
      updateUserAuth,
      this.httpOptions
    );
  }

  AddPackageCities(
    packageCities: PackageCity[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/cities";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = packageCities;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }
  SetActivitiesReceivedWithActivityTypeData(
    allPackageActivties: PackageActivity[],
    allActivityTypes: Activity[]
  ) {
    allPackageActivties.forEach((packageActivity) => {
      allActivityTypes.forEach((activityType) => {
        if (activityType.Id === packageActivity.ActivityId) {
          packageActivity.Name = activityType.Name;
        }
      });
    });
    return allPackageActivties;
  }
  SetHotelsReceivedWithActivityTypeData(
    allPackageHotels: PackageActivity[],
    allHotels: Activity[]
  ) {
    allPackageHotels.forEach((packageActivity) => {
      allHotels.forEach((hotelType) => {
        if (hotelType.Id === packageActivity.ActivityId) {
          packageActivity.Name = hotelType.Name;
        }
      });
    });
    return allPackageHotels;
  }

  AddPackageCountries(
    allPackageCountries: PackageCountry[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/countries";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = allPackageCountries;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }

  AddPackageMealPlans(
    allPackageMealPlans: PackageMealPlan[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/mealplans";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = allPackageMealPlans;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }

  AddPackageCategories(
    allPackageCategories: PackageCategory[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/categories";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = allPackageCategories;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }
  AddPackageHotel(
    thePackageHotels: PackageHotel[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/hotels";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = thePackageHotels;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }
  AddPackageActivity(
    thePackageActivities: PackageActivity[],
    packageId: number,
    userId: number
  ) {
    // debugger;
    const url = this.baseUrl + "/" + packageId + "/activities";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = thePackageActivities;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }
  AddPackageService(
    thePackageServices: PackageServiceTable[],
    packageId: number,
    userId: number
  ) {
    // debugger;
    const url = this.baseUrl + "/" + packageId + "/services";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = thePackageServices;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }
  AddPackageFacilities(
    thePackageFacilities: PackageFacility[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/facilities";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = thePackageFacilities;
    return this._http.post(url, postWithUserAuth);
  }

  AddPackageCitiesNew(
    allPackageCities: PackageCityNew[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/cities";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = allPackageCities;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }

  AddPackageAmenities(
    allPackageAmenities: PackageAmenity[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/amenities";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = allPackageAmenities;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }

  AddPackageExtraServices(
    allPackageAmenities: PackageExtraService[],
    packageId: number,
    userId: number
  ) {
    const url = this.baseUrl + "/" + packageId + "/amenities";
    const postWithUserAuth = new PostWithUserAuth();
    postWithUserAuth.UserId = userId;
    postWithUserAuth.Data = allPackageAmenities;
    return this._http.post(url, postWithUserAuth, this.httpOptions);
  }

  GetReactiveFormGroupValue(fomrGroup: FormGroup, field: string) {
    // debugger;
    if (fomrGroup.get(field).value) {
      return fomrGroup.get(field).value;
    }
    return null;
  }

  SetUrlParamIdAsNumber() {
    let id = 0;
    this.sub = this.route.params.subscribe((params) => {
      id = +params["id"]; // (+) converts string 'id' to a number
      return id;
    });
    return id;
  }

  SetFormGroupControlNameValue(
    formGroup: FormGroup,
    fieldName: string,
    value: any
  ) {
    formGroup.get(fieldName).setValue(value);
  }

  UploadPackageImages(form) {
    const url = environment.API_URL + "/api/uploadimage";
    const body = {
      // tslint:disable-next-line:quotemark
      "Content-Disposition":
        "form-data; name='image'; filename='" + form.name + "'",
      // tslint:disable-next-line:quotemark
      "Content-Type": "image/jpeg",
    };
    const settings = {
      async: true,
      crossDomain: true,
      url: "http://localhost:8001/api/uploadimage",
      method: "POST",
      headers: {
        "cache-control": "no-cache",
        "postman-token": "dfd49cf8-6315-1740-7345-7c4be0516dd2",
      },
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
    };
    return this._http.post(url, settings, {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
    });
  }

  GetAllByPaging(page: number, pageSize: number) {
    // debugger
    return this._http.get(
      environment.API_URL +
      "/api/package/paging/all?page=" +
      page +
      "&page_size=" +
      pageSize
    );
  }

  Search(page: number, pageSize: number, model: any) {
    // debugger

    var url =
      environment.API_URL +
      "/api/package/search/all?page=" +
      page +
      "&page_size=" +
      pageSize +
      "&flag=" +
      model.Flag;
    if (model.Price) {
      url = url + "&price=" + model.Price;
    }
    if (model.Rating) {
      url = url + "&rating=" + model.Rating;
    }
    if (model.Category) {
      url = url + "&category=" + model.Category;
    }
    if (model.Facility) {
      url = url + "&facility=" + model.Facility;
    }
    if (model.RatingId) {
      url = url + "&ratingId=" + model.RatingId;
    }
    if (typeof model.MinPrice == "number") {
      url = url + "&minPrice=" + model.MinPrice + "&maxPrice=" + model.MaxPrice;
    }
    if (model.CountriesId) {
      url = url + "&CountriesId=" + model.CountriesId;
    }
    if (model.CitiesId) {
      url = url + "&CitiesId=" + model.CitiesId;
    }

    if (model.Travells) {
      url = url + "&travells=" + model.Travells;
    }
    if (model.Destination) {
      url = url + "&destination=" + model.Destination;
    }
    if (model.StartDate) {
      url = url + "&StartDate=" + model.StartDate;
    }
    if (model.EndDate) {
      url = url + "&EndDate=" + model.EndDate;
    }
    if (model.Night) {
      url = url + "&Night=" + model.Night;
    }

    if (model.MinNight && model.MaxNight) {
      url = url + "&MinNight=" + model.MinNight + "&MaxNight=" + model.MaxNight;
    }
    if (model.IsOffered) {
      url = url + "&IsOffered=" + model.IsOffered;
    }

    return this._http.get(url);
  }

  GetAllByFeatured(page, page_size) {
    return this._http.get(
      environment.API_URL +
      "/api/package/featured/all?page_size=" +
      page_size +
      "&page=" +
      page
    );
  }

  SearchByCountry(model: any) {
    var url =
      environment.API_URL +
      "/api/package/searchByCountry/all?CountryId=" +
      model.CountryId +
      "&CityId=" +
      model.CityId;
    return this._http.get(url);
  }

  AddToLocalStorage(model: any) {
    this.localservice.AddCountriesAndCities(model);
  }
  countriesAndCities;
  AddCountriesAndCities(model: any) {
    this.countriesAndCities = [];
    this.countriesAndCities.push(model);
    this.storageService.set(
      "checkout",
      JSON.stringify(this.countriesAndCities)
    );
  }
  cites;
  AddCites(model: any) {
    // debugger
    this.cites = [];
    this.cites.push(model);
    this.storageService.set("cites", JSON.stringify(this.cites));
  }

  GetCountriesAndCities() {
    return this.localservice.GetCountriesAndCities();
  }

  GetMaxPrice() {
    return this._http.get("api/package/maxprice/all");

    //return this._http.get('http://trippat.com/api/package/maxprice/all');

    // return this._http.get('http://trippat.com/api/package/minprice/all');
  }
  GetMinPrice() {
    return this._http.get(environment.API_URL + "/api/package/minprice/all");
  }

  MakeQueryString(model) {
    var queryString = "?";
    Object.keys(model).forEach((key) => {
      if (typeof (model[key] == "string") && model[key].length == 0) return;
      queryString += key + "=" + model[key] + "&";
    });
    return (queryString = queryString.substring(0, queryString.length - 1));
  }

  CheckDuplicate(model) {
    // debugger
    var url =
      environment.API_URL +
      "/api/package/duplicate?Name=" +
      model.Name +
      "&AdultPrice=" +
      model.AdultPrice +
      "&Id" +
      model.Id;
    return this._http.get(url);
  }

  GetPackageBySpecialOffer(page, page_size) {
    var url =
      environment.API_URL +
      "/api/package/special/offer?page_size=" +
      page_size +
      "&page=" +
      page;
    return this._http.get(url);
  }

  CountPackages() {
    var url = environment.API_URL + "/api/package/count/packages";
    return this._http.get(url);
  }

  GetPriceBySearch(
    page: number,
    pageSize: number,
    model: any,
    searchType: number
  ) {
    if (searchType == 1) {
      var url =
        environment.API_URL +
        "/api/package/price/bysearch?page=" +
        page +
        "&page_size=" +
        pageSize +
        "&flag=" +
        model.Flag;
    } else {
      var url =
        environment.API_URL +
        "/api/package/maxnight/bysearch?page=" +
        page +
        "&page_size=" +
        pageSize +
        "&flag=" +
        model.Flag;
    }

    if (model.Price) {
      url = url + "&price=" + model.Price;
    }
    if (model.Rating) {
      url = url + "&rating=" + model.Rating;
    }
    if (model.Category) {
      url = url + "&category=" + model.Category;
    }
    if (model.Facility) {
      url = url + "&facility=" + model.Facility;
    }
    if (model.RatingId) {
      url = url + "&ratingId=" + model.RatingId;
    }
    if (typeof model.MinPrice == "number") {
      url = url + "&minPrice=" + model.MinPrice + "&maxPrice=" + model.MaxPrice;
    }
    if (model.CountriesId) {
      url = url + "&CountriesId=" + model.CountriesId;
    }
    if (model.CitiesId) {
      url = url + "&CitiesId=" + model.CitiesId;
    }

    if (model.Travells) {
      url = url + "&travells=" + model.Travells;
    }
    if (model.Destination) {
      url = url + "&destination=" + model.Destination;
    }
    if (model.StartDate) {
      url = url + "&StartDate=" + model.StartDate;
    }
    if (model.EndDate) {
      url = url + "&EndDate=" + model.EndDate;
    }
    if (model.Night) {
      url = url + "&Night=" + model.Night;
    }
    if (model.NightRange) {
      url = url + "&MinNight=" + model.MinNight + "&MaxNight=" + model.MaxNight;
    }

    if (model.Adult) {
      url = url + "&Adult=" + model.Adult;
    }

    if (model.Child) {
      url = url + "&Child=" + model.Child;
    }

    return this._http.get(url);
  }

  GetByCustomer(CustomerId: number, page_size: number, page: number) {
    // debugger
    var url =
      this.baseUrl +
      "/getby/customer?page_size=" +
      page_size +
      "&customerId=" +
      CustomerId +
      "&page=" +
      page;
    return this._http.get(url);
  }
}
