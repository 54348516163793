<ng-container *ngIf="tour">
  <div class="tour_container">
    <!-- <div class="ribbon_3 popular"><span>طلب عالي</span></div> -->
    <div class="ribbon_3" *ngIf="tour.IsFeatured"><span>عرض خاص</span></div>
    <div class="img_container {{tripType}}-card" (click)="navigate()">
      <div class="groupPack" *ngIf="tour.IsGroupPackage">
        <i class="fa fa-users" aria-hidden="true"></i>
        متبقي
        {{tour.GroupAvailableSheet}}
        مقاعد
      </div>
      <a>
        <img [src]="tour.images.length ? tour.images[0].ImagePath : 'assets/img/trip-description-img-2.jpg'" width="800"
          height="533" class="img-fluid" alt="Image">
        <div class="short_info">
          <ng-container *ngIf="tour.categories.length">
            <i class="icon_set_1_icon-44"></i>
            <ng-container *ngFor="let category of tour.categories;let i = index">
              {{category.Name}}
              <ng-container *ngIf="tour.categories.length > 1 && i !== tour.categories.length - 1">
                ,</ng-container>
            </ng-container>
          </ng-container>
          <span class="price">
            {{tour.AdultPriceAfterOffer}}
            <sup>ر.س</sup>
            <b>للشخص الواحد</b>
          </span>
          <span class="price beforeDiscount" *ngIf="tour.Offer">
            قبل
            <span class="del">
              {{tour.AdultPrice}}
              ر.س
            </span>
            -{{tour.Offer}}%
          </span>
        </div>
      </a>
    </div>
    <div class="tour_title ">
      <div class="row">
        <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11 equal-height-col pull-left col-eq-height">
          <h3 (click)="navigate()" *ngIf="tour.Nights">
            <strong>{{tour.Nights}} ليالي</strong>
          </h3>
          <h3 (click)="navigate()">
            <strong>{{cities.join(" - ")}}</strong>
          </h3>
          <h3 class="tour_name" (click)="navigate()">
            <strong>{{tour.Name}}</strong>
          </h3>
          <div class="rating">
            <i class="icon-star-2 voted" [ngClass]="{'voted': tour.rate >= star}" *ngFor="let star of [1,2,3,4,5]"></i>
            <!-- <small>(75)</small> -->
          </div>
        </div>
        <!-- end rating -->
        <div class="wishlist col-lg-1 col-md-1 col-sm-1 col-xs-1 equal-height-col col-eq-height"
          [ngClass]="{'faved': tour.Favourite}" (click)="toggleFavourite(tour.Id, tour.Favourite)">
          <a class="tooltip_flip tooltip-effect-1">+<span class="tooltip-content-flip">
              <!-- <span class="tooltip-back">إضافة
                      النشاط
                      إلى المفضلة</span> -->
            </span></a>
        </div>
        <!-- End wish list-->
      </div>
    </div>
  </div>
</ng-container>