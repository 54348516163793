import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { CheckoutService } from "src/app/services/checkout.service";
import { PaymentComponent } from "src/app/shared/components/payment/payment.component";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.css"],
})
export class InvoiceComponent implements OnInit {
  checkoutId;
  checkoutData;

  constructor(
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.checkoutId = this.route.snapshot.params.checkoutId;
    this.getCheckOutData(this.checkoutId);
  }

  getCheckOutData(id) {
    this.checkoutService.GetById(id).subscribe((data: any) => {
      this.checkoutData = data;
    });
  }

  open() {
    const dialogRef = this.dialog.open(PaymentComponent, {
      data: {
        PaymentCheckoutId: this.checkoutData.PaymentCheckoutId,
      },
      disableClose: true,
      hasBackdrop: true,
    });
    dialogRef.afterClosed().subscribe((data: any) => {
    });
  }
}
