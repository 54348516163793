import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TripProductService {
  constructor(private _http: HttpClient) {}

  GetAll() {
    // debugger
    return this._http.get(environment.API_URL + "/api/tripproduct/all");
  }

  Create() {}

  Update() {}
  Delete() {}

  GetById() {}
}
