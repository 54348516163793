import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { LocalService } from "./local.service";
import { CheckoutModel } from "../models/classes/checkout";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  constructor(private _http: HttpClient, private localservice: LocalService) {}

  checkout = [];

  AddToLocalStorage(model: any) {
    this.localservice.AddCheckOutDetail(model);
  }

  GetFromLocalStorage() {
    return this.localservice.GetCheckOutDetail();
  }

  GetAll() {
    // debugger;
    const url = environment.API_URL + "/api/checkout/all";
    return this._http.get(url);
  }

  getAllWithPagination(page, size, search?, sortbydate?) {
    // https://dev.trippat.com/api/checkout/paging/all?page=1&page_size=100&search=%20test
    const url = `${
      environment.API_URL
    }/api/checkout/paging/all?page=${page}&page_size=${size}${
      search ? "&search=" + search : ""
    }${sortbydate ? "&sortbydate=" + sortbydate : ""}`;
    return this._http.get(url);
  }

  GetById(id: number) {
    // debugger
    const url = environment.API_URL + "/api/checkout/";
    return this._http.get(url + id);
  }

  GetBookingId(id: number) {
    // debugger
    const url = environment.API_URL + "/api/checkout/booking/";
    return this._http.get(url + id);
  }

  Create(model: CheckoutModel) {
    const url = environment.API_URL + "/api/checkout/create";
    return this._http.post(url, model);
  }

  Update(model: CheckoutModel) {
    const url = environment.API_URL + "/api/checkout/update/" + model.Id;
    return this._http.post(url, model);
  }

  Verify(model: CheckoutModel) {
    const url = environment.API_URL + "/api/checkout/verify/" + model.Id;
    return this._http.post(url, model);
  }

  Delete(id: number) {
    const url = environment.API_URL + "/api/checkout/delete/";
    return this._http.post(url + id, null);
  }

  Payment(model: any) {
    // debugger
    const url = environment.API_URL + "/api/payment";
    return this._http.post(url, model);
  }

  addTravelars(checkoutId, data) {
    // /api/checkout/{checkoutId}/travellers/add
    return this._http.post(
      `${environment.API_URL}/api/checkout/${checkoutId}/travellers/add`,
      { Travellers: data }
    );
  }

  verifyPayment(PaymentCheckoutId) {
    return this._http.post(
      `${environment.API_URL}/api/checkout/verifypayment`,
      { PaymentCheckoutId }
    );
  }
  // ​/api​/checkout​/needToAcceptDateChange​/paging​/all?page
  getPendingBooks(page, size, search?, sortbydate?) {
    const url = `${
      environment.API_URL
    }/api/checkout/needToAcceptDateChange/paging/all?page=${page}&page_size=${size}${
      search ? "&search=" + search : ""
    }${sortbydate ? "&sortbydate=" + sortbydate : ""}`;
    return this._http.get(url);
  }

  // /api/booking/approveUpdateDateRequest/{bookingId}

  approveUpdateDateRequestbyId(id: number) {
    // debugger
    const url =
      environment.API_URL + `/api/booking/approveUpdateDateRequest/${id}`;
    return this._http.post(url, "");
  }

  confirmaPayWithCash(checkoutId: number) {
    const url = `${environment.API_URL}/api/checkout/confirmCashPaymentFromAdmin/${checkoutId}`;
    return this._http.post(url, "");
  }

  reGeneratePaymentCheckoutId(PaymentCheckoutId: number) {
    const url = `${environment.API_URL}/api/checkout/reGeneratePaymentCheckoutId`;
    return this._http.post(url, {PaymentCheckoutId});
  }
}
