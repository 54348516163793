import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { SessionManagement } from "../session-management";
import { Router } from "@angular/router";
import { Service } from "../services/service";
import { Data } from "../models/data";
import { StorageService } from "../shared/services/storage.service";
import { isPlatformBrowser } from "@angular/common";
declare var $: any;
@Component({
  selector: "app-user-layout",
  templateUrl: "./user-layout.component.html",
  styleUrls: ["./user-layout.component.css"],
})
export class UserLayoutComponent implements OnInit {
  imagePath = this.sessionManagement.GetUser().UserProfile.ProfilePicPath;
  Name = this.sessionManagement.GetUser().UserProfile.FirstName;

  constructor(
    private _router: Router,
    private _service: Service,
    private storageService: StorageService,
    private sessionManagement: SessionManagement,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    // tslint:disable-next-line:prefer-const
    let self = this;
    if (isPlatformBrowser(this.platformId)) {
      $(document).on("blur", "#EditName", function () {
        self.ChangeName();
      });
    }
  }
  Logout() {
    this.storageService.clear();
    this._router.navigateByUrl("/");
  }
  OpenFileDailog() {
    if (isPlatformBrowser(this.platformId)) {
      $("[name='UploadProfilePic']").click();
    }
  }
  imageUpload(event) {
    // debugger
    let ProfilePic = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      // debugger
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
      ) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          ProfilePic = reader.result;
          this._service.UserService.UpdateProfilePicture({
            Id: this.sessionManagement.GetUser().Id,
            ProfilePic: ProfilePic,
          }).then((data: Data<string>) => {
            // debugger;
            if (data.returnCode === 0) {
              this.imagePath = data.data;
              const user = this.sessionManagement.GetUser();
              user.UserProfile.ProfilePicPath = this.imagePath;
              this.sessionManagement.SetUser(user);
            }
          });
        };
      }
    }
  }
  EditName() {
    if (isPlatformBrowser(this.platformId)) {
      $("#EditName").attr("contenteditable", true);
    }
  }
  ChangeName() {
    let name;
    if (isPlatformBrowser(this.platformId)) {
      name = $("#EditName").text();
    }
    if (name.length === 0) {
      alert("Please enter name");
    }
    this._service.UserService.ChangeName({
      Id: this.sessionManagement.GetUser().Id,
      FirstName: name,
    }).then((data: Data<string>) => {
      if (data.returnCode === 0) {
        this.Name = name;
        const user = this.sessionManagement.GetUser();
        user.UserProfile.FirstName = name;
        this.sessionManagement.SetUser(user);
      } else {
        this.Name = this.sessionManagement.GetUser().UserProfile.FirstName;
      }
    });
  }
}
