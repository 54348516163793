import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { UpdateUserAuth } from "../models/rest/update-user-auth";
import { PageSEO } from "../models/pageseo";
import { PageSEOUI } from "../models/pageseoUI";
import { Update } from "../models/rest/update";

@Injectable({
    providedIn: "root",
})

export class PageSeoService {
    httpOptions = { headers: { "Content-Type": "application/json" } };
    constructor(
        private _http: HttpClient,
        private route: ActivatedRoute,
    ) { }
    baseUrl = `${environment.API_URL}/api/seo`;
    pageseoAddFormGroup = new FormGroup({
        Id: new FormControl(""),
        PageName: new FormControl("", [Validators.required]),
        PageSlug: new FormControl("", [Validators.required]),
        MetaTitle: new FormControl("", [Validators.required]),
        MetaDescription: new FormControl("", [Validators.required]),
        MetaRobots: new FormControl("", [Validators.required]),
        MetaKeywords: new FormControl("", [Validators.required]),
        MetaCanonical: new FormControl("", [Validators.required]),
    });

    /* Create:: New Page SEO*/
    Add(_model: PageSEO) {
        const httpOptions = { headers: { "Content-Type": "application/json" } };
        return this._http.post<PageSEO>(this.baseUrl, _model, httpOptions);
    }

    /* Update:: Existing Page SEO */
    Update(id: number, model: PageSEO) {
        return this._http.post(
            `${environment.API_URL}/api/seo/update/${id}`,
            model
        );
    }

    /* GET:: All Page SEO  */
    GetAll() {
        const url = `${environment.API_URL}/api/seo/all`;
        return this._http.get<PageSEO[]>(url);
    }

    /* GET:: Page SEO Detail by Id */
    GetSeoByID(id: number) {
        const url = `${environment.API_URL}/api/seo/${id}`;
        return this._http.get(url);
    }

    /* GET:: Page SEO Detail by Page Slug */
    GetSeoByURL(pageSlug: string = "/") {
        const url = `${environment.API_URL}/api/seo/page/${encodeURIComponent(pageSlug)}`;
        // const params = new HttpParams();
        // params.append('pageSlug', pageSlug);
        return this._http.get(url);
    }
}