import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StorageService } from "../shared/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class LocalService {
  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {}

  private checkout = [];
  private countriesAndCities = [];
  private liClass: string;
  private TotalTreveler = [];

  AddCheckOutDetail(model: any) {
    this.checkout = [];
    this.checkout.push(model);
    this.storageService.set("checkout", JSON.stringify(this.checkout));
  }
  GetCheckOutDetail() {
    this.checkout = JSON.parse(this.storageService.get("checkout"));
    return this.checkout;
  }

  AddCountriesAndCities(model: any) {
    this.countriesAndCities = [];
    this.countriesAndCities.push(model);
    this.storageService.set(
      "countriesAndCities",
      JSON.stringify(this.countriesAndCities)
    );
  }

  GetCountriesAndCities() {
    this.countriesAndCities = JSON.parse(
      this.storageService.get("countriesAndCities")
    );
    this.storageService.remove("countriesAndCities");
    return this.countriesAndCities;
  }

  AddHtmlClasses(value: string) {
    this.liClass = value;
  }
  AddTotalTreveler(model: any) {
    this.TotalTreveler = [];
    this.TotalTreveler = model;
    this.storageService.set(
      "TotalTreveler",
      JSON.stringify(this.TotalTreveler)
    );
  }

  GetTotalTrevler() {
    this.TotalTreveler = JSON.parse(this.storageService.get("TotalTreveler"));
    //this.storageService.remove("TotalTreveler");
    return this.TotalTreveler;
  }

  GetHtmlClasses() {}
}
