import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CampaignsService } from "src/app/services/campaigns.service";
import { MetaTagsService } from "src/app/services/meta-tags.service";

@Component({
  selector: "app-campaigns-view",
  templateUrl: "./campaigns-view.component.html",
  styleUrls: ["./campaigns-view.component.css"],
})
export class CampaignsViewComponent implements OnInit, OnDestroy {
  campaignId: number;
  campaignSlug: string;
  campaign;

  constructor(
    private activatedRoute: ActivatedRoute,
    private campaignsService: CampaignsService,
    private translate: TranslateService,
    private metaTagsService: MetaTagsService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.campaignId) {
        if (isNaN(params.campaignId)) {
          this.campaignSlug = params.campaignId;
          this.getCampaignBySlug(this.campaignSlug);
        } else {
          this.campaignId = Number(params.campaignId);
          this.getCampaign(this.campaignId);
        }
        // this.campaignId = Number(params.campaignId);
        // this.getCampaign(this.campaignId);
      }
    });
  }

  getCampaign(campaignId) {
    this.campaignsService
      .getCampaignById(campaignId)
      .subscribe((result: any) => {
        this.campaign = result.data;
        this.setData(this.campaign);
      });
  }
  getCampaignBySlug(campaignId) {
    this.campaignsService
      .getCampaignBySlug(campaignId)
      .subscribe((result: any) => {
        this.campaign = result.data;
        this.setData(this.campaign);
      });
  }

  setData(data) {
    let title;
    let cities = [];
    let description;
    let desc;
    if (data) {
      data.Packages.map((p) =>
        p.Cities.map((c) => {
          if (!cities.includes(c.Name)) {
            cities.push(c.Name);
          }
        })
      ).join(",");
      // cities = cities.join(",");
      description = data.Description.replaceAll(/<[^>]*>/g, "");
      desc = `${this.translate.instant(
        "ASpecialGroupOfExcursionsAndTouristExperiences"
      )} - ${description} - ${cities}`;
    }
    title = `
    ${this.translate.instant(
      "ExcursionsAndSpecialExperiencesInSelectedDestinations"
    )} - ${cities}
    `;
    // this.metaTagsService.setDescription(desc);
    // this.metaTagsService.setTitle(title);

    data.MetaTitle && this.metaTagsService.setTitle(data.MetaTitle ? data.MetaTitle : data.name)
    data.MetaDescription && this.metaTagsService.setMetaByName("description", data.MetaDescription);
    data.MetaKeywords && this.metaTagsService.setMetaByName("keywords", data.MetaKeywords);
    data.MetaTitle && this.metaTagsService.setMetaByName("robots", data.MetaRobots);
    data.MetaTitle && this.metaTagsService.setMetaByName("twitter:title", data.MetaTitle ? data.MetaTitle : data.name);
    data.MetaTitle && this.metaTagsService.setMetaByName("twitter:description", data.MetaDescription);
    data.ImagePath && this.metaTagsService.setMetaByName("twitter:image", data.ImagePath);
    // data.MetaDescription && this.metaTagsService.setMetaByProperty("og:type", data.MetaDescription);
    data.MetaTitle && this.metaTagsService.setMetaByProperty("og:title", data.MetaTitle ? data.MetaTitle : data.name);
    data.ImagePath && this.metaTagsService.setMetaByProperty("og:image", data.ImagePath);
    data.MetaDescription && this.metaTagsService.setMetaByProperty("og:url", data.MetaDescription);
    data.MetaDescription && this.metaTagsService.setMetaByProperty("og:description", data.MetaDescription);
    data.MetaDescription && this.metaTagsService.setMetaByProperty("og:site_name", data.MetaDescription);
  }

  ngOnDestroy() {
    this.setData("");
  }
}
