<div class="prtm-wrapper">
  <header class="prtm-header">
    <nav class="navbar navbar-default">
      <div class="container-fluid no-padding">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
            aria-expanded="false" aria-controls="navbar"> <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span><span class="icon-bar"></span> <span class="icon-bar"></span>
          </button>
          <button class="c-hamburger c-hamburger--htra prtm-bars pull-right hidden-lg hidden-md hidden-sm">
            <span>toggle menu</span> </button>
          <div class="prtm-logo">
            <a class="navbar-brand" [routerLink]="['/']"><img class="img-responsive display-ib"
                src="assets/img/logo.png" alt="logo" width="226" height="31"></a>
          </div>
        </div>
        <div id="navbar" class="navbar-collapse collapse" data-hover="dropdown">
          <ul class="nav navbar-nav navbar-right">
            <li class="dropdown">
              <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">
                <img *ngIf="imagePath" class="img-responsive display-ib mrgn-l-sm img-circle" [src]="imagePath"
                  width="64" height="64">
                <img *ngIf="!imagePath" class="img-responsive display-ib mrgn-l-sm img-circle"
                  src="assets/img/default-user.png" width="64" height="64" alt="User-image">
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li><a [routerLink]="['/user/profile/settings']"><i class="fa fa-cog"></i> Account Setting</a></li>
                <li role="separator" class="divider"></li>
                <li><a (click)="Logout()"><i class="fa fa-power-off"></i>Logout</a></li>
              </ul>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  </header>
  <div class="prtm-main">
    <div class="prtm-sidebar">
      <div class="prtm-sidebar-back"> </div>
      <div class="prtm-sidebar-nav-wrapper">
        <div class="scroll_text">
          <div class="prtm-sidebar-menu">
            <nav class="sidebar-nav collapse">
              <ul class="list-unstyled sidebar-menu">
                <li *ngIf="notExpert" [routerLink]="['/admin/dashboard']" routerLinkActive="active">
                  <a>
                    <span>{{'Dashboard' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/user']" routerLinkActive="active">
                  <a>
                    <span>{{'Users' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/package']" routerLinkActive="active">
                  <a>
                    <span>{{'ElPackages'
                      | translate}}</span>
                  </a>
                </li>
                <li *ngIf="!notExpert" [routerLink]="['/expert/package']" routerLinkActive="active">
                  <a>
                    <span>{{'ElPackages'
                      | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/checkout']" routerLinkActive="active">
                  <a>
                    <span>{{'Booking' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/pendingCheckouts']" routerLinkActive="active">
                  <a>
                    <span>
                      {{'Pending Booking' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/campaigns']" routerLinkActive="active">
                  <a>
                    <span>
                      {{'Campaigns' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/hotel']" routerLinkActive="active">
                  <a>
                    <span>{{'Hotel' |
                      translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/activity']" routerLinkActive="active">
                  <a>
                    <span>{{'Activity Panel' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="!notExpert" [routerLink]="['/expert/activity']" routerLinkActive="active">
                  <a>
                    <span>{{'Activity Panel' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/service']" routerLinkActive="active">
                  <a>
                    <span>{{'Service'
                      | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/country']" routerLinkActive="active">
                  <a>
                    <span>{{'Country' |
                      translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/city']" routerLinkActive="active">
                  <a>
                    <span>{{'City' |
                      translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/packagecategory']" routerLinkActive="active">
                  <a>
                    <span>{{'Package Category' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/activitycategory']" routerLinkActive="active">
                  <a>
                    <span>{{'Activity Category' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/activityinclude']" routerLinkActive="active">
                  <a>
                    <span>{{'Activity Include' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/hotelinclude']" routerLinkActive="active">
                  <a>
                    <span>{{'Hotel Include' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/facility']" routerLinkActive="active">
                  <a>
                    <span>{{'Facility'
                      | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/customtrip/list']" routerLinkActive="active">
                  <a>
                    <span>{{'Custom Trip' | translate}}</span>
                  </a>
                </li>

                <li *ngIf="notExpert" [routerLink]="['/admin/planedtriplist']" routerLinkActive="active">
                  <a>

                    <span>{{'Planed Trip' | translate}}</span>
                  </a>
                </li>
                <li *ngIf="notExpert" [routerLink]="['/admin/seo']" routerLinkActive="active">
                  <a>
                    <span>{{'SEO' | translate}}</span>
                  </a>
                </li>
              </ul>

              <ul class="list-unstyled sidebar-menu bottom-fixed">
                <li>
                  <a (click)="Logout()"><i class="fa fa-sign-out" aria-hidden="true"></i><span>Logout</span></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="prtm-content-wrapper">
      <router-outlet></router-outlet>
      <footer class="footer-wrapper">
        <div class="prtm-footer clearfix">
          <div class="row footer-area pad-lr-md">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <p>Copyright 2022 Trippat | All Rights Reserved</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 text-right">
              <a [routerLink]="['/']"><img class="footer-logo" src="assets/img/footer-logo.png" alt="footer logo"></a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>