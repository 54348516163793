import { Pageview } from "./PageView";

export class SiteVisitor {
    Id: number;
    UserId: number;
    IP: string;
    TotalVisit: number;
    CreatedOn: Date;
    UpdatedOn: Date;
    PageView: Pageview;
}