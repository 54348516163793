import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Activity } from "../models/Activity";
import { PostWithUserAuth } from "../models/rest/post-user-auth";
import { UpdateUserAuth } from "../models/rest/update-user-auth";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivityImage } from "../models/classes/ActivityImage";
import { Data } from "../models/data";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  constructor(private _http: HttpClient) { }

  private baseUrl = environment.API_URL + "/api/activity";
  httpOptions = { headers: { "Content-Type": "application/json" } };

  public activityFormGroup = new FormGroup({
    Id: new FormControl(""),
    Name: new FormControl("", [Validators.required]),
    CityId: new FormControl("", [Validators.required]),
    CountryId: new FormControl("", [Validators.required]),
    AdultPrice: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[.\d]+$/),
    ]),
    ChildPrice: new FormControl("", [
      Validators.required,
      Validators.pattern(/^[.\d]+$/),
    ]),
    Duration: new FormControl("", [Validators.required]),
    Description: new FormControl("", [Validators.required]),
    MeetingPoint: new FormControl(""),
    IsActive: new FormControl(""),
    StatusId: new FormControl("", [Validators.required]),
    Highlight: new FormControl(""),
    MeetingPointMapLink: new FormControl(""),
    KnowBeforeYouGo: new FormControl(""),
  });

  GetAll() {
    return this._http.get(this.baseUrl);
  }
  GetAllActivities() {
    return this._http.get(`${this.baseUrl}/all`);
  }
  GetSitemap() {
    return this._http.get(`${this.baseUrl}/sitemap`);
  }

  GetAllByPaging(
    page: number,
    page_size: number,
    search: string,
    AgencyId: number
  ) {
    var url =
      this.baseUrl + "/paging/all?page=" + page + "&page_size=" + page_size;
    if (search) {
      url = url + "&search=" + search;
    }
    if (AgencyId) {
      url = url + "&expertId=" + AgencyId;
    }
    return this._http.get(url);
  }

  GetAllByParams(params) {
    return this._http.get(`${this.baseUrl}/paging/all?${params}`);
  }

  GetAllByAgency(id) {
    // debugger
    return this._http.get(this.baseUrl + "/agency/" + id);
  }

  GetActivitiesByCityId(id: any) {
    return this._http.get(this.baseUrl + "/city/" + id);
  }

  GetByCityAndAgency(agencyId: any, cityId: any) {
    // debugger;
    return this._http.get(
      this.baseUrl + "/agency/all?cityId=" + cityId + "&agencyId=" + agencyId
    );
  }

  feturedActivity(page, pageSize) {
    // https://dev.trippat.com/api/activity/fetured/activity?page=1&page_size=6
    return this._http.get(
      `${this.baseUrl}/fetured/activity?page=${page}&page_size=${pageSize}`
    );
  }

  Add(model: Activity) {
    const postWithUserAuthObj = new PostWithUserAuth();
    // postWithUserAuthObj.UserId = 1;
    // postWithUserAuthObj.Data = model;
    return this._http.post(`${this.baseUrl}/create`, model, this.httpOptions);
  }

  UpsertActivity(id: number, model: Activity) {
    // debugger;
    return this._http.post(
      environment.API_URL + "/api/activity/update/" + id,
      model
    );
  }

  DeleteActivity(id: number) {
    // debugger;
    return this._http.post(
      environment.API_URL + "/api/activity/delete/" + id,
      null
    );
  }

  GetActivity(id: number) {
    const url = this.baseUrl + "/" + id;
    return this._http.get(url);
  }
  GetActivityBySlug(slug: string) {
    const url = this.baseUrl + "/slug/" + slug;
    return this._http.get(url);
  }

  /**
   * Will receive the FormGroup which contains all the values to be updated.
   * Will map each control key and value to the Activity model and return
   *
   * @param {FormGroup} activityFormGroup
   * @returns
   * @memberof ActivityService
   */
  MakeTheActivityModel(activityFormGroup: FormGroup) {
    const model = new Activity();
    for (const key in activityFormGroup.controls) {
      if (activityFormGroup.controls.hasOwnProperty(key)) {
        model[key] = this.GetReactiveFormGroupValue(activityFormGroup, key);
      }
    }
    return model;
  }

  GetReactiveFormGroupValue(fomrGroup: FormGroup, field: string) {
    // debugger;
    if (fomrGroup.get(field).value) {
      return fomrGroup.get(field).value;
    }
    return null;
  }

  AddImage(model: any) {
    const url = environment.API_URL + "/api/uploadimage/upload/";
    return this._http.post<ActivityImage>(url, model, this.httpOptions);
  }

  /**
   * Will get a FormGroup to set values for and the activity model which contains all the values to be set.
   * @param activityFormGroup
   * @param activity
   */
  SetupActivityFormGroupFields(
    activityFormGroup: FormGroup,
    activity: Activity
  ) {
    activityFormGroup.patchValue(activity);
  }

  SetFormGroupControlNameValue(
    formGroup: FormGroup,
    fieldName: string,
    value: any
  ) {
    formGroup.get(fieldName).setValue(value);
  }

  IsActivityExist(name: string, id: number) {
    // debugger
    let promise = new Promise<{ [key: string]: any } | null>(
      (resolve, reject) => {
        this._http
          .post(environment.API_URL + "/api/activity/activityexist", {
            Name: name,
            Id: id,
          })
          .subscribe((data: Data<string>) => {
            if (data.returnCode == 0) {
              resolve(null);
            } else {
              resolve({ exist: true });
            }
          });
      }
    );
    return promise;
  }

  //tripPlener

  GetAllPlanerTrip(cityids: any) {
    // debugger
    //this.baseUrl="http://trippat.com/api/activity"

    var url = this.baseUrl + "/getactivity/byplanercities?cityids=" + cityids;
    return this._http.get(url);
  }

  Getallmactivity(cityids: any) {
    // debugger
    //this.baseUrl="http://trippat.com/api/activity"

    var url = this.baseUrl + "/getact/bymuliplecity?cityids=" + cityids;
    return this._http.get(url);
  }

  GetMaxPrice(cityids: any) {
    // debugger

    var url = this.baseUrl + "/gettripactivity/MaxPrice?cityids=" + cityids;
    return this._http.get(url);
  }

  GetByCityId(CityId: any) {
    // debugger
    var url = this.baseUrl + "/gettripactivity/bycityId?CityId=" + CityId;
    return this._http.get(url);
  }

  GetAllByFeatured(page, page_size) {
    return this._http.get(
      this.baseUrl +
      "/fetured/activity?page_size=" +
      page_size +
      "&page=" +
      page
    );
  }
}
