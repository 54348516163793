<div class="container campaignView">
  <div class="row">
    <div class="col-xs-12 pt-70 pb-150 pad-l-lg bg-white">
      <h3 class="mrgn-b-md title">{{campaign?.Name}}</h3>

      <p class="description" [innerHTML]="campaign?.Description"></p>

      <div class="row">
        <div class="col-lg-4 col-md-6 wow" *ngFor="let package of campaign?.Packages">
          <app-trip-box [trip]="package" type="package"></app-trip-box>
        </div><!-- End col -->
      </div>

    </div>
  </div>
</div>
