import { User } from "./models/user";
import { Injectable } from "@angular/core";
import { StorageService } from "./shared/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class SessionManagement {
  constructor(private storageService: StorageService) {}
  SetUser(model: User) {
    this.storageService.set("CurrentUser", JSON.stringify(model));
  }
  GetUser(): User {
    return <User>JSON.parse(this.storageService.get("CurrentUser"));
  }
  SetDefaultCompany(id: string) {
    this.storageService.set("DefaultCompany", id);
  }
  GetDefaultCompany() {
    return this.storageService.get("DefaultCompany");
  }

  SetPaymentViewData(model: any) {
    this.storageService.set("model", JSON.stringify(model));
  }

  GetPaymentViewData() {
    return <User>JSON.parse(this.storageService.get("model"));
  }

  setTripPlanerData(model: any) {
    this.storageService.set("Tripdata", JSON.stringify(model));
  }

  getTripPlanerData(): any {
    return <User>JSON.parse(this.storageService.get("Tripdata"));
  }

  setids(model: any) {
    this.storageService.set("ids", JSON.stringify(model));
  }

  getids(): any {
    return <User>JSON.parse(this.storageService.get("ids"));
  }

  setTripCites(model: any) {
    this.storageService.set("Citydata", JSON.stringify(model));
  }

  getTripCites(): any {
    return <User>JSON.parse(this.storageService.get("Citydata"));
  }

  setFinalTripCites(model: any) {
    this.storageService.set("FinalCitydata", JSON.stringify(model));
  }

  getFinalTripCites(): any {
    return <User>JSON.parse(this.storageService.get("FinalCitydata"));
  }

  setTrevalData(model: any) {
    this.storageService.set("TravelData", JSON.stringify(model));
  }

  getTrevalData(): any {
    return <User>JSON.parse(this.storageService.get("TravelData"));
  }

  setSechduledData(model: any) {
    this.storageService.set("FinalCitydata", JSON.stringify(model));
  }

  getSechduledData(): any {
    return <User>JSON.parse(this.storageService.get("FinalCitydata"));
  }

  setCustomSechduledData(model: any) {
    this.storageService.set("data", JSON.stringify(model));
  }

  getCustomSechduledData(): any {
    return <User>JSON.parse(this.storageService.get("data"));
  }

  setplanedTrip(model: any) {
    this.storageService.set("data", JSON.stringify(model));
  }

  getplanedTrip(): any {
    return <User>JSON.parse(this.storageService.get("data"));
  }

  setplanedTripDates(model: any) {
    this.storageService.set("data", JSON.stringify(model));
  }

  getplanedTripDates(): any {
    return <User>JSON.parse(this.storageService.get("data"));
  }
}
