import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Favourite } from "../models/classes/favourite";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class FavouriteService {
  DeleteFavourite(packageId: number, arg1: string) {
    throw new Error("Method not implemented.");
  }
  AddFavourite(packageId: number, arg1: string) {
    throw new Error("Method not implemented.");
  }
  constructor(private _http: HttpClient) {}

  GetAll() {
    const url = environment.API_URL + "/api/favourite/all";
    return this._http.get(url);
  }

  myFavPagination(page?, page_size?) {
    // https://dev.trippat.com/api/favourite/myFavourites?page=1&page_size=10
    const args = {
      page,
      page_size,
    };
    const params = Object.keys(args)
      .map((key) => {
        if (args[key]) {
          return `${key}=${args[key]}`;
        }
      })
      .filter((i) => i != null || i != undefined)
      .join("&");
    const url = `${environment.API_URL}/api/favourite/myFavourites?${params}`;
    return this._http.get(url);
  }

  GetById(id: number) {
    const url = environment.API_URL + "/api/favourite/";
    return this._http.get(url + id);
  }

  Create(model: Favourite) {
    const url = environment.API_URL + "/api/favourite/create";
    return this._http.post(url, model);
  }

  Update(id: number, model: Favourite) {
    const url = environment.API_URL + "/api/favourite/update/" + id;
    return this._http.post(url, model);
  }

  Delete(id: number) {
    const url = environment.API_URL + "/api/favourite/delete/";
    return this._http.post(url + id, null);
  }

  GetFavoruteByUserID(id: number) {
    const url = environment.API_URL + "/api/favourite/user/";
    return this._http.get(url + id);
  }
}
