<form [formGroup]="formGroup">
        <div class="row">
            <div class="col-lg-12 col-xs-12 mrgn-b-lg">
                <label>{{"Name"|translate}}</label>
                <input type="text" style="max-width:270px;" class="form-control" placeholder="{{'Name'|translate}}"
                    formControlName="Name" />
            </div>
            <div class="col-lg-12 col-xs-12 mrgn-b-lg">
                <label>{{"Description" |translate}}</label>
                <textarea rows="10" class="form-control" placeholder="{{'Description'|translate}}"
                    formControlName="Description"> </textarea>
            </div>
        </div>

        <!-- <a class="btn reset-btn" (click)="reset()">Reset</a> -->
    </form>
