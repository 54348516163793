import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Hotel } from "../models/Hotel";
import { Update } from "../models/rest/update";
import { HotelAmenityDb } from "../models/hotelAmenityDb";
import { HotelRoomDb } from "../models/HotelRoomDb";
import { HotelServiceUI } from "../models/hotelServiceUI";
import { HotelImages } from "../models/HotelImages";
import { Server } from "../server";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HotelService {
  /**
   *
   */
  httpOptions = { headers: { "Content-Type": "application/json" } };
  constructor(private _http: HttpClient, private _server: Server) { }

  GetAll() {
    const url = environment.API_URL + "/api/hotel/all";
    return this._http.get<Hotel[]>(url);
  }

  GetAllByPaging(
    page: number,
    pageSize: number,
    search: string,
    AgencyId: number,
    RoleId: number
  ) {
    var url =
      environment.API_URL +
      "/api/hotel/paging/all?page=" +
      page +
      "&page_size=" +
      pageSize;
    if (search) {
      url = url + "&search=" + search;
    }
    if (AgencyId) {
      url = url + "&agency=" + AgencyId;
    }
    if (RoleId) {
      url = url + "&role=" + RoleId;
    }
    return this._server.Get(url);
  }

  GetAllByAgency(agencyId) {
    const url = environment.API_URL + "/api/hotel/all";
    return this._http.get(url + "/agency/" + agencyId);
  }

  GetHotelsByCityId(id) {
    const url = environment.API_URL + "/api/hotel/city/" + id;
    return this._http.get(url);
  }

  GetHotelsByCityAndAgency(agencyId, cityId) {
    const url =
      environment.API_URL +
      "/api/hotel/Agency?cityId=" +
      cityId +
      "&agencyId=" +
      agencyId;
    return this._http.get(url);
  }

  Add(_hotel: Hotel) {
    const url = environment.API_URL + "/api/hotel/create";
    return this._http.post<Hotel>(url, _hotel, this.httpOptions);
  }

  UpdateHotel(id: number, model: Hotel) {
    return this._http.post(
      environment.API_URL + "/api/Hotel/update/" + id,
      model
    );
  }

  //Delete Hotel
  Delete(id: number, model: Hotel) {
    return this._http.post(
      environment.API_URL + "/api/Hotel/delete/" + id,
      model
    );
  }

  AddImage(model: any) {
    // debugger;
    const url = environment.API_URL + "/api/uploadimage/upload";
    return this._http.post<HotelImages>(url, model, this.httpOptions);
  }

  GetHotel(id: number) {
    const url = environment.API_URL + "/api/hotel/" + id;
    return this._http.get(url);
  }
  GetHotelServices(id: number) {
    const url = environment.API_URL + "/api/hotel/" + id + "/services";
    return this._http.get(url);
  }
  /**
   * Will upsert the HotelServices
   *
   * @param {number} hotelId
   * @param {HotelService[]} hotelServiceArray
   * @returns
   * @memberof HotelService
   */
  UpsertHotelServices(hotelId: number, hotelServiceArray: HotelServiceUI[]) {
    const url = environment.API_URL + "/api/hotel/" + hotelId + "/services";
    // tslint:disable-next-line:prefer-const
    let updateObject = new Update();
    updateObject.update = true;
    updateObject.data = hotelServiceArray;
    return this._http.post(url, updateObject, this.httpOptions);
  }
  DeleteService(hotelId: number, serviceId: number) {
    const url =
      environment.API_URL + "/api/hotel/" + hotelId + "/service/" + serviceId;
    return this._http.delete(url);
  }

  GetHotelAmenities(id: number) {
    const url = environment.API_URL + "/api/hotel/" + id + "/amenities";
    return this._http.get(url);
  }
  /**
   * Will upsert the HotelAmeties
   *
   * @param {number} hotelId
   * @param {HotelService[]} hotelAmenityArray
   * @returns
   * @memberof HotelService
   */
  UpsertHotelAmenities(hotelId: number, hotelAmenityArray: HotelAmenityDb[]) {
    const url = environment.API_URL + "/api/hotel/" + hotelId + "/amenities";
    // tslint:disable-next-line:prefer-const
    let updateObject = new Update();
    updateObject.update = true;
    updateObject.data = hotelAmenityArray;
    return this._http.post(url, updateObject, this.httpOptions);
  }
  DeleteAmenity(hotelId: number, amenityId: number) {
    const url =
      environment.API_URL + "/api/hotel/" + hotelId + "/amenity/" + amenityId;
    return this._http.delete(url);
  }

  GetHotelRooms(id: number) {
    const url = environment.API_URL + "/api/hotel/" + id + "/rooms";
    return this._http.get(url);
  }
  /**
   * Will upsert the HotelRooms
   *
   * @param {number} hotelId
   * @param {HotelService[]} hotelRoomArray
   * @returns
   * @memberof HotelService
   */
  UpsertHotelRooms(hotelId: number, hotelRoomArray: HotelRoomDb[]) {
    const url = environment.API_URL + "/api/hotel/" + hotelId + "/rooms";
    // tslint:disable-next-line:prefer-const
    let updateObject = new Update();
    updateObject.update = true;
    updateObject.data = hotelRoomArray;
    return this._http.post(url, updateObject, this.httpOptions);
  }
  DeleteRoom(hotelId: number, roomId: number) {
    const url =
      environment.API_URL + "/api/hotel/" + hotelId + "/room/" + roomId;
    return this._http.delete(url);
  }

  //Trip planer

  GetHotelByCityId(cityids: any) {
    // debugger
    const url =
      environment.API_URL + "/api/hotel/tripHotel/byCity?cityIds=" + cityids;
    return this._http.get(url);
  }

  GetHotelByCityIdAndRating(cityids: any, ratingids: any) {
    // debugger
    const url =
      environment.API_URL +
      "/api/hotel/tripplanner/stars?cityIds =" +
      cityids +
      "&ratingids =" +
      ratingids;
    return this._http.get(url);
  }
}
