import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from "../models/role";
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
  })

  export class RoleServices
  {
    constructor(private _http: HttpClient) {  };

    GetAll() {
        const url = environment.API_URL +'/api/role';
        return this._http.get(url);
      }

  }