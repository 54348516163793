import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { DropzoneConfigInterface, DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { NgModule } from "@angular/core";
import {
  SocialAuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialLoginModule,
} from "angularx-social-login";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserModule } from "@angular/platform-browser";
import { RoutingModule } from "./routing/routing.module";
import { DatePipe } from "@angular/common";
import { FullCalendarDirective } from "./fullcalander.directive";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpConfigInterceptor } from "./utilities/interceptor/httpconfig.interceptor";
import { InvoiceComponent } from "./offer/invoice/invoice.component";
import { HomeComponent } from "./home/home.component";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ExpertDashboardComponent } from "./expert/expert-dashboard/expert-dashboard.component";
import { PrivacyPolicyComponent } from "./static/privacy-policy/privacy-policy.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { CampaignsViewComponent } from "./campaigns/campaigns-view/campaigns-view.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { GoogleTagManagerModule } from "angular-google-tag-manager";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


let base64;
export const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: `${environment.API_URL}/api/uploadimage/upload/`,
  maxFilesize: 50,
  acceptedFiles: "image/*",
  method: "POST",
  // headers: {
  //   "Content-Type": "application/json",
  //   "Accept-Language": localStorage.getItem("Language") || "en",
  // },
  paramName: "file",
  transformFile: function (file, done) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const imageData = event.target["result"];
      base64 = imageData;
      done(base64);
    };
    reader.readAsDataURL(file);
  },
  init: function () {
    const dz = this;
    dz.on("sending", function (file, xhr, formData) {
      xhr.send(
        JSON.stringify({
          file: base64,
        })
      );
    });
  },
  maxFiles: 1,
};

@NgModule({
  declarations: [
    AppComponent,
    FullCalendarDirective,
    InvoiceComponent,
    HomeComponent,
    ExpertDashboardComponent,
    PrivacyPolicyComponent,
    CampaignsViewComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    ToastrModule.forRoot(), // ToastrModule added
    MatDialogModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    NgbModule,
    RoutingModule,
    NgSelectModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ModalModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      defaultLanguage: "ar",
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      },
  }),
  // GoogleTagManagerModule.forRoot({
  //   id: "GTM-MT3W2XQ",
  // })
  ],
  providers: [
    DatePipe,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("465668584252484"),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "802263654964-s9n2qjfuh0kgn9u1i8j9dvo1jin5l34j.apps.googleusercontent.com"
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },

    { provide: MatDialogRef, useValue: {} },
    MatDialogModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: "googleTagManagerId", useValue: "GTM-MT3W2XQ" },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
