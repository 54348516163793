export const environment = {
  production: false,
  // API_URL: "http://localhost:3000",
  API_URL: "https://api.trippat.com",
  paymentGateScriptUrl: "https://oppwa.com/v1/paymentWidgets.js?checkoutId=",
  paymentGateScriptUrl4Test: "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=",
  firebaseConfig: {
    apiKey: "AIzaSyDoSPtGTFz-76mhuJf_VSkK2r1xIou4C48",
    authDomain: "newproj-236818.firebaseapp.com",
    projectId: "newproj-236818",
    storageBucket: "newproj-236818.appspot.com",
    messagingSenderId: "78360540441",
    appId: "1:78360540441:web:c3d1caa1135425879e818"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
