import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { UserBookingModel } from "../models/classes/userBooking";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserbookingService {
  constructor(private _http: HttpClient, private route: ActivatedRoute) {}

  GetAll() {
    return this._http.get(environment.API_URL + "/api/booking/all");
  }

  myBooks(page?, page_size?, sortbybooktype?, sortbydate?) {
    // https://dev.trippat.com/api/booking/mybooks?page=1&page_size=10&sortbydate=ASC&sortbybooktype=package
    const args = {
      page,
      page_size,
      sortbybooktype,
      sortbydate,
    };
    const params = Object.keys(args)
      .map((key) => {
        if (args[key]) {
          return `${key}=${args[key]}`;
        }
      })
      .filter((i) => i != null || i != undefined)
      .join("&");
    const url = `${environment.API_URL}/api/booking/mybooks?${params}`;
    return this._http.get(url);
  }

  GetAllByPaging(page: number, page_size: number) {
    return this._http.get(
      environment.API_URL +
        "/api/booking/paging/all?page=" +
        page +
        "&page_size=" +
        page_size
    );
  }

  GetAllByAgency(id: number) {
    return this._http.get(
      environment.API_URL + "/api/booking/all/agency/" + id
    );
  }

  GetById(id: number) {
    // debugger
    const url = environment.API_URL + "/api/booking/";
    return this._http.get(url + id);
  }

  Create(data) {
    // debugger
    const url = environment.API_URL + "/api/booking/create";
    return this._http.post(url, data);
  }

  getBookDataById(id) {
    return this._http.get(`${environment.API_URL}/api/booking/${id}`);
  }

  Update(model: UserBookingModel) {
    const url = environment.API_URL + "/api/booking/update/" + model.Id;
    return this._http.post(url, model);
  }

  Delete(id: number) {
    const url = environment.API_URL + "/api/booking/delete/";
    return this._http.post(url + id, null);
  }

  Varify(model: UserBookingModel) {
    const url = environment.API_URL + "/api/booking/verify/" + model.Id;
    return this._http.post(url, model);
  }

  GetUpComing(page: number, page_size: number, id: number) {
    return this._http.get(
      environment.API_URL +
        "/api/booking/user/upcoming/all?page=" +
        page +
        "&page_size=" +
        page_size +
        "&Id=" +
        id
    );
  }

  GetPassed(page: number, page_size: number, id: number) {
    return this._http.get(
      environment.API_URL +
        "/api/booking/user/passed/all?page=" +
        page +
        "&page_size=" +
        page_size +
        "&Id=" +
        id
    );
  }

  CountUserBooking() {
    return this._http.get(environment.API_URL + "/api/booking/count/booking");
  }

  GetBookingDetails(bookingId: number) {
    // debugger
    return this._http.get(
      environment.API_URL +
        "/api/booking/user/booking/detail?bookingId=" +
        bookingId
    );
  }

  GetbyUserId(page: number, page_size: number, id: number) {
    // debugger
    return this._http.get(
      environment.API_URL +
        "/api/booking/byuser/all?page=" +
        page +
        "&page_size=" +
        page_size +
        "&Id=" +
        id
    );
  }

  EditPackageDescription(model: any) {
    // debugger
    return this._http.post("api/editbooking/Editbooking/create", model);
  }

  GetByPackageId(PackageId: number) {
    // debugger
    return this._http.get(
      "api/booking/user/booking/package?PackageId=" + PackageId
    );
  }

  cancel(id: number) {
    const url = `${environment.API_URL}/api/booking/cancel/${id}`;
    return this._http.post(url, {});
  }

  updateStartDate(id: number, UpdateStartDate) {
    const body = { UpdateStartDate };
    const url = `${environment.API_URL}/api/booking/update/${id}`;
    return this._http.post(url, body);
  }
}
